import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";

function DropdownCountry() {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: "Australien", code: "AU" },
        { name: "Brasilien", code: "BR" },
        { name: "Chinois", code: "CN" },
        { name: "Français", code: "FR" },
        { name: "Allemand", code: "DE" },
        { name: "Indien", code: "IN" },
        { name: "Japonais", code: "JP" },
        { name: "espagnole", code: "ES" },
        { name: "Anglais", code: "US" }
    ];

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: "18px" }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span style={{ fontFamily: "24px" }}>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                    className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: "24px" }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="flex justify-content-center border-0 shadow-none rounded-0 w-100 py-0 selectCountry">
            <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Français"
                filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="w-full md:w-14rem border-0 fw-bold shadow-none" />
        </div>
    )
}

export default DropdownCountry