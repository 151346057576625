import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const formatDate = (date) => {
  return date ? moment(date).format("DD/MM/YYYY [à] HH:mm") : "";
};
const formatDatee = (dateString) => {
  return format(new Date(dateString), "dd/MM/yyyy");
};
export { formatDate, formatDatee };