import React from "react";
import sender from "../../../image/sender.png";
import happy from "../../../image/happy.png";
import ph_star from "../../../image/ph_star.png";
import InfoCard from "../../../components/InfoCard/InfoCard";
import { Link, useSearchParams } from "react-router-dom";
import CardHeader from "./CardHeader";
import { CardColumn, OkIconContainer, PreTacheText } from "./Prestay";
import ok from "../../../image/ok.png";
import styled from "styled-components";
import {
  DETAIL_CLIENT,
  RELATION_CLIENT_PATH,
  ELEMENT_TRAITE,
} from "../../../routes/navigation/navigationPaths";
import DrapeauSenegal from "../../../image/DrapeauSenegal.jpg";
import emotion from "../../../image/satisfait.png";
import stars from "../../../image/stars.png";
import "./Scroll.css";
import { TextBut } from "../../../pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageurCss";
import "../../CustomComponent/Calendar/calendrier.css";
import CustomButton from "../../../components/CustomComponent/Boutton/CustomButton";
import { Filtrer } from "./traiteStyle";
import { PiCalendarBold } from "react-icons/pi";
import { InputSelectDate,
  ContainSelectData, SpanIcon } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { FormateName } from "utils/FormateName";

export const PsContain = styled.div`
  margin-top: 10px;
  font-family: Roboto;
`;

const Traiter = ({ reservations, formatDate, dateNow }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDate = searchParams.get("dateDebut") || "";

  const filteredReservations = reservations.filter((item) => {
    return (
      (!filterDate || item.date_debut_sejour.toLowerCase().includes(filterDate.toLowerCase()))
    );
  });
  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }

  const reservationsPreStay = filteredReservations.filter(
    (reservation) => new Date(reservation.date_debut_sejour) > dateNow
  );
  const reservationsInStay = filteredReservations.filter(
    (reservation) =>
      new Date(reservation.date_debut_sejour) <= dateNow &&
      new Date(reservation.date_fin_sejour) >= dateNow
  );
  const reservationsPostStay = filteredReservations.filter(
    (reservation) => new Date(reservation.date_fin_sejour) < dateNow
  );
  const uniqueDates = [...new Set(reservations.map((item) => item.date_debut_sejour))];
  return (
    <div className="overflow-y-scroll custom-scrollbar">
      <div
      className="row card d-flex flex-row justify-content-center mt-1 py-2 px-3"
      style={{ border: "none", borderRadius: "10px", overflow: "hidden" }}
    >
      <div className="card-header border-0 p-0">
        <div className="">
        <div className="d-md-flex d-block align-items-center justify-content-between">
          <div
            className="col-md-6 d-flex align-items-center col-lg-6"
            style={{
              fontFamily: "Roboto",
              fontSize: "19px",
              fontWeight: 500,
              color: "#212529",
            }}
          >
            À traiter
            <span
              style={{
                fontFamily: "Roboto",
                fontSize: "19px",
                fontWeight: 500,
                color: "#949494",
                paddingLeft: "8px",
              }}
            >
              ({reservationsPreStay.length})
            </span>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <Link to={`/${RELATION_CLIENT_PATH}/${ELEMENT_TRAITE}`}>
              <CustomButton
                className="d-flex align-items-center gap-1 "
                borderRadius="30px"
                border="1px solid #FFC857"
                backgroundColor="#FFC857"
                padding={"6px 10px"}
                fontSize={"14px"}
              >
                <TextBut
                  style={{
                    color: "#000000",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Éléments traités
                </TextBut>
              </CustomButton>
            </Link>
          </div>
        </div>
        <div className="mt-4 p-0 col-lg-9 d-md-flex d-block align-items-center gap-2">
          <Filtrer style={{ margin: "0px", padding: "0" }}>Filtrer par</Filtrer>
          <ContainSelectData>
                  <InputSelectDate
                    value={filterDate}
                    onChange={(e) => handleFilterChange("dateDebut", e.target.value)}
                  >
                    <option value="">jj/mm/aa</option>
                    {uniqueDates.map((date, index) => (
                      <option key={index} value={date}>
                        {formatDate(date)}
                      </option>
                    ))}
                  </InputSelectDate>
                  <SpanIcon>
                    <PiCalendarBold className=""/>
                  </SpanIcon>
          </ContainSelectData>
        </div>
        </div>
      </div>
      <div className="card-body hide-scrollbar pt-0 px-0">
        <PsContain>
          <CardHeader
            image={sender}
            text="Pre-Stay"
            Complete="Complété le"
            style={{ marginLeft: "0px" }}
          />
          {reservationsPreStay.length > 0 ? (
            reservationsPreStay.map((reservation, index) => (
              <Link
                to={{
                  pathname: `${DETAIL_CLIENT}/${reservation.voyageur_details.id}`,
                  state: {
                    clientName: `${reservation.voyageur_details.prenom} ${reservation.voyageur_details.nom}`,
                  },
                }}
                key={index}
              >
                <InfoCard
                  padding="9px"
                  size="15px"
                  name={`${FormateName(reservation.voyageur_details.prenom, 5)} ${FormateName(reservation.voyageur_details.nom, 5)}`}
                  Pays="Senegal"
                  countryImage={DrapeauSenegal}
                  type="Pre-Stay"
                  preStayDate={formatDate(reservation.date_debut_sejour)}
                  classImg="hidden"
                  classMidle="hidden"
                />
              </Link>
            ))
          ) : (
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          )}
        </PsContain>
        <PsContain>
          <CardHeader image={happy} text="In-Stay" />
          {reservationsInStay.length > 0 ? (
            reservationsInStay.map((reservation, index) => (
              <Link
                to={{
                  pathname: `${DETAIL_CLIENT}/${reservation?.voyageur_details?.id}`,
                  state: {
                    clientName: `${reservation?.voyageur_details?.prenom} ${reservation?.voyageur_details?.nom}`,
                  },
                }}
                key={index}
              >
                <InfoCard
                  classMidle="col-md-4 px-5"
                  padding="7px"
                  size="15px"
                  name={`${FormateName(reservation.voyageur_details.prenom, 5)} ${FormateName(reservation.voyageur_details.nom, 5)}`}
                  Pays="Senegal"
                  countryImage={DrapeauSenegal}
                  type="In-Stay"
                  preStayDate={formatDate(reservation.date_debut_sejour)}
                  emotion={emotion}
                  emotionText="Satisfait"
                  classImg="hidden"
                />
              </Link>
            ))
          ) : (
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          )}
        </PsContain>
        <PsContain>
          <CardHeader image={ph_star} text="Post-Stay" />
          {reservationsPostStay.length > 0 ? (
            reservationsPostStay.map((reservation, index) => (
              <Link
                to={{
                  pathname: `${DETAIL_CLIENT}/${reservation.voyageur_details.id}`,
                  state: {
                    clientName: `${reservation.voyageur_details.prenom} ${reservation.voyageur_details.nom}`,
                  },
                }}
                key={index}
              >
                <InfoCard
                  classMidle="col-md-4 px-5"
                  padding="9px"
                  size="15px"
                  name={`${FormateName(reservation.voyageur_details.prenom, 5)} ${FormateName(reservation.voyageur_details.nom, 5)}`}
                  Pays="Senegal"
                  countryImage={DrapeauSenegal}
                  type="Post-Stay"
                  preStayDate={formatDate(reservation.date_debut_sejour)}
                  star={stars}
                />
              </Link>
            ))
          ) : (
            <CardColumn style={{ marginLeft: "20px" }}>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          )}
        </PsContain>
      </div>
    </div>
    </div>
  );
};

export default Traiter;
