// import SejourCadre from "components/GestionEnvoie/sejour/SejourCadre";
// import React, { useState } from "react";
// import { Cercle, Textarea, TitreQst } from "../StyledAdmin";
// import { useFormContext } from "react-hook-form";
// import { useTranslation } from "react-i18next";
// // import BoutonLang from "components/GestionEnvoie/BoutonLang";

// export function Sejour() {
//     const { t } = useTranslation();
//     const [activeTexta, setActiveTexta] = useState(false);
//     const { register } = useFormContext();

//     const handeleClik = (e) => {
//         e.preventDefault()
//         setActiveTexta(true)
//     }
//     const cadreData1 = [
//     { label: "Rose" }, { label: "Rouge" }, { label: "Orange" }, { label: "Blanc" }, { label: "Pas de fleur" },
//     ]
//     const cadreData2 = [
//         { label: "Entre 16° et 18°" }, { label: "Entre 18° et 20°" }, { label: "Standard : Entre 20° et 22°" },
//         { label: "Entre 22° et 24°" }, { label: "Entre 24° et 26°" },
//     ]
//     const cadreData3 = [
//         { label: "Gare St Lazare" }, { label: "Gare de l’Est" }, { label: "Gare du Nord" },
//         { label: "Gare de Lyon" }, { label: "Aéroport d’Orly" },
//     ]
//     const cadreData4 = [
//         { label: "Aéroport Charles de Gaulle" },
//         { label: "Aéroport Le Bourget" }, { label: "Station de métro" },
//     ]
//     const cadreData5 = [
//         { label: "Oui" }, { label: "non" },
//     ]
//     return (
//         <div className="container py-5 " style={{ paddingLeft: "8rem" }}>
//             <TitreQst>{t("Quelle couleur de fleur préférez-vous ?")}</TitreQst>
//             <div className="d-flex gap-2 mt-3">
//                 { cadreData1.map((cadre, index) => (
//                      <SejourCadre key={index} label={cadre.label} {...register("checkSejour")}/>
//                 ))}
//             </div>
//             <div className="mt-5 d-flex align-items-center gap-3" onClick={handeleClik}>
//                 <Cercle type="radio" id="reac" {...register("caractere")}></Cercle>
//                 <TitreQst className="titre-question" htmlFor="reac" >{t("Qu’est ce qui vous fait vous sentir unique ?")}</TitreQst>
//                     { activeTexta && <Textarea className="form-control rounded-0 ms-5"></Textarea> }
//                 {/* <div className="w-75">
//                 </div> */}
//             </div>
//             <TitreQst className="">{t("Avez-vous une température de confort ?")}</TitreQst>
//             <div className="d-flex gap-2 mt-3">
//                 { cadreData2.map((cadre, index) => (
//                      <SejourCadre key={index} label={cadre.label} {...register("checkSejour")}/>
//                 ))}
//             </div>
//             <TitreQst className="">{t("Avez-vous besoin d’informations pour rejoindre l’hôtel le jour de votre arrivée ?")}</TitreQst>
//             <div className="d-flex gap-2 mt-3">
//                 { cadreData3.map((cadre, index) => (
//                      <SejourCadre key={index} label={cadre.label} {...register("checkSejour")}/>
//                 ))}
//             </div>
//             <div className="d-flex gap-2 mt-2">
//             { cadreData4.map((cadre, index) => (
//                      <SejourCadre key={index} label={cadre.label} {...register("checkSejour")}/>
//                 ))}
//             </div>
//             <TitreQst className="">{t("Avez-vous des préférences ou des choses à nous faire savoir ?")}</TitreQst>
//             <div className="d-flex gap-2 mt-2">
//             { cadreData5.map((cadre, index) => (
//                      <SejourCadre key={index} label={cadre.label} {...register("checkSejour")}/>
//                 ))}
//             </div>

//         </div>
//     )
// }
import React, { useState } from "react";
import Text from "components/FormulairePreStay/Text";
import styled from "styled-components";
import BoutonNeomorphisme from "components/FormulairePreStay/BoutonNeomorphisme";
import Bouton from "components/FormulairePreStay/Bouton";
import Titre from "components/FormulairePreStay/Titre";

const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: start;
    flex-direction: column;
    `;
const IconContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
`;
const Textarea = styled.textarea`
    border: 1px solid #D9D9D9;
    outline: none;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 16px;
    border-radius: 5px;
`;
const ContainButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`

function Sejour({ handleCancel, handleClick }) {
    const [isButtonActive, setIsButtonActive] = useState(false);
    const toggleButton = () => {
        setIsButtonActive((prev) => !prev);
    };
    return (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="flex justify-content-center"><Titre
                text="Mieux vous servir"
                style={{
                    fontFamily: "Roboto",
                    fontSize: "28px",
                    fontWeight: 600,
                }}
            /></div>
            <Container className="col-lg-6 col-sm-12 col-md-6 col-xl-6">
                <Text content={"Êtes-vous interéssé par nos recommandations à propos..."} />
                <IconContainer>
                    <input type="radio" name="recommandations" id="acivite" />
                    <label htmlFor="acivite">Des activités et divertissements environnants ?</label>
                </IconContainer>
                <IconContainer>
                    <input type="radio" name="recommandations" id="restaurant" />
                    <label htmlFor="restaurant">Des restaurants proches de l'hôtel ?</label>
                </IconContainer>
                <IconContainer>
                    <input type="radio" name="recommandations" id="autres" />
                    <label htmlFor="autres">Une autre demande ?</label>
                </IconContainer>
                <Text content={"Un souhait ou besoin particulier...nous ferons le maximum pour accéder à votre demande"} />
                <IconContainer>
                    <input type="radio" name="recommandations" id="chambre" />
                    <label htmlFor="chambre">Concernant votre chambre ?</label>
                </IconContainer>
                <IconContainer>
                    <input type="radio" name="recommandations" id="sejour" />
                    <label htmlFor="sejour">Concernant votre séjour en général ?</label>
                </IconContainer>
                <Text content={"Transports"} />
                <IconContainer>
                    <input type="radio" name="transport" id="transport" />
                    <label htmlFor="transport">Avez-vous besoin d’informations pour rejoindre l’hôtel le jour de votre arrivée ?</label>
                </IconContainer>
                <Text content={"Avez-vous des préférences ou des choses à nous faire savoir ?"} />
                <IconContainer>
                    {/* <input type="radio" name="choses" id="boolean"/>
        <label htmlFor="boolean">Non</label> */}
                    <BoutonNeomorphisme onClick={toggleButton} active={isButtonActive} type="button" />
                    <span>{isButtonActive ? "Oui" : "Non"}</span>
                </IconContainer>
                {isButtonActive && (
                    <Textarea rows={"4"} />
                )}
            </Container>
            <ContainButton>
                <Bouton onClick={handleCancel} name={"Précédent"} />
                <Bouton onClick={handleClick} name={"Suivant"} />
            </ContainButton>
        </div>
    )
}

export default Sejour