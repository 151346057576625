// import InfoPersonnel from "components/RelationClientDetail/InfoClient/InfoPersonnel";
import React from "react";
import styled from "styled-components";
import chevronRight from "../../image/chevron-right.png";

const InfoCardContain = styled.div`
  padding: ${(props) => props.padding};
  /* margin-bottom: 5px; */
  border-bottom: 1px solid #DEE2E6;
`;

const PrestaDate = styled.div`
  display: flex;
  justify-content: flex-end;
  /* padding-top: 12px; */
`;

const Date = styled.p`
  font-size: 14px;
  color: #667085;
  margin-top: 28px
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CountryImage = styled.img`
  width: 30px; /* Taille réduite */
  height: 30px; /* Taille réduite */
  border-radius: 50%;
`;
const EmotionText = styled.span`
font-size: 12px;
margin-left: 2px;
margin-top: 10px
`;


const InfoCard = ({ padding, name, Pays, countryImage, preStayDate, size, classMidle, func, emotionText, emotion, star, classImg }) => {
  return (
    <InfoCardContain onClick={func} padding={padding} className="card-body py-0 row d-flex justify-content-between align-items-center"
      style={{ color: "#000", fontSize: size, cursor: "pointer" }}>
      <div className="col-md-4 px-2">
        <InfoRow className="">
          <CountryImage src={countryImage} alt="Country flag" />
          <div>
            <p className="custom-paragraph" style={{ marginBottom: "0px", fontFamily: "Roboto", fontWeight: 500, color: "#212529", width: "300px" }}>{name}</p>
            <p className="custom-paragraph" style={{ marginBottom: "3px", fontSize: "12px", color: "#6C757D" }}>{Pays}</p>
          </div>
        </InfoRow>
      </div>
      <div className={classMidle}>
        <div className="d-flex">
          <img src={emotion} />
          <EmotionText>{emotionText}</EmotionText>
          <img src={star} alt="stars" style={{ width: "70px" }} className={classImg} />
       </div>
      </div>
      <PrestaDate className="col-md-4">
        <Date style={{ marginLeft: "20px" }}>{preStayDate}</Date>
        <img src={chevronRight} alt="Chevron right" style={{ width: "20px", height: "20px", marginLeft: "10px", marginTop: "25px" }} />
      </PrestaDate>
    </InfoCardContain>
  );
};

export default InfoCard;
