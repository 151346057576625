import React from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

function Modal({ opened, onClosed, title, children, confirme, cancel }) {
  const modalRef = useRef(null);

  if (!opened) {
    return null;
  }

  document.addEventListener("click", (e) => {
    if (e.target.className === "modal-container") {
      onClosed();
    }
  });

  return createPortal(
    <ModalBackdrop >
      <ModalContent ref={modalRef}>
        <div className="d-flex justify-content-between align-items-center">
          <span>{title ? title : "Modal Title"}</span>
          <div className="modal-close-icon" onClick={() => onClosed()}>
            <AiOutlineClose size={20} />
          </div>
        </div>
        <hr />
        <div>{children}</div>
       <div className="d-flex justify-content-end">
       <button onClick={confirme} className="btn btn-danger m-2">Oui</button>
       <button onClick={cancel} className="btn btn-secondary m-2">Non</button>
       </div>
      </ModalContent>
    </ModalBackdrop>,
    document.getElementById("root")
  );
}

export default Modal;
