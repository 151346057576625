/*
|--------------------------------------------------------------------------
! Add Your Application andpoints here
|--------------------------------------------------------------------------
*/
export const authentificate = {
  onLogin() {
    return "auth/login/";
  },
  onLogout() {
    return "auth/logout/";
  },
  onRefreshToken() {
    return "auth/refresh/";
  },
  onForgotPassword() {
    return "request-reset-password/";
  },
  onResetPassword() {
    return "reset-password/";
  },
};

export const voyageurReservationEndpoint = {
  addNewVoyageurReservation() {
    return "voyageur_reservation/";
  },
  getVoyageurReservations: "voyageur_reservation/:id/",
  updateVoyageurReservations: "update_voyageur_reservation/:id/",
};
export const serviceBasesEndepoint = {
  getServiceBases: "services_base",
};
export const serviceCommandesEndepoint = {
  getserviceCommandes: "services/",
  ServicesCommandees() {
    return "services_commandees/";
  },
  ServicesCommandeesById(serviceId) {
    return `services_commandees/${serviceId}/`;
  },
};
export const formulaireByIdEndepoint = {
  getVoyageurReservationsById() {
    return "voyageur_reservation/:id/";
  },
  getServicesCommandees() {
    return "services_commandees/";
  },
  ServicesCommandees() {
    return "services_commandees/";
  },
  getListeServices() {
    return "services_commandees/listes/";
  },
  getServices() {
    return "services/";
  },
  formPreStayById: "hotel/:id/",
};
export const deleteReservationEndpoint = {
  suppReservation: "reservation/:id/",
};
export const voyageurEndpoint = {
  addNewVoyageur() {
    return "voyageur/";
  },
  deleteVoyageur: "voyageur/:id/",
  updateVoyageur: "voyageur/:id/",
  importerDonneesVoyageur: "importer_donnees/voyageur/",
};

export const reservationEndpoint = {
  addNewReservation() {
    return "reservation/";
  },
  updateReservation: "reservation/:id/",
};

export const roomsEndpoint = {
  getRooms() {
    return "chambre/";
  },
  // Autres méthodes
};

export const textePrepare = {
  addTextePrepare: "textes_prepare/",
  deleteTextePrepare: "textes_prepare/:id/",
  sendEmail: "send_mail/",
};
