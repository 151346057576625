import React from "react";
import styled from "styled-components";
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import { GoDotFill } from "react-icons/go";
import { GrValidate } from "react-icons/gr";
import { Link, useNavigate, useParams } from "react-router-dom";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import {
  DETAIL_CLIENT,
  GESTION_RESERVATION_PATH,
  RELATION_CLIENT_PATH,
  UPDATE_RESERVATION_PATH,
} from "routes/navigation/navigationPaths";
import { useDispatch } from "react-redux";
import { setActiveTab } from "redux/features/SplitShellSlice";
import { useGetReservationByIdQuery } from "actions/reservation/query";
// import { useGetReservationByIdQuery } from "actions/apiSliceRTK";

const Heading1 = styled.h1`
  font-size: 26px;
  font-family: Roboto;
  padding-top: 50px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const DivContain1 = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MesBouttons = styled.div`
  display: flex;
`;
const Titre = styled.h6`
  margin: 30px 0 30px 63px;
`;
const DivContain2 = styled.div`
  margin: 30px;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
`;
const DivContain3 = styled.div`
  border: 1px solid #d3d3d3;
  margin: 60px;
  border-radius: 7px;
`;
const DivTitlePeriode = styled.h6`
  font-size: 14px;
  margin: 15px 0 5px 25px;
  padding: 25px 0 0 0;
`;
const CardPeriode = styled.div`
  font-size: 12px;
`;
const DivContain4 = styled.div`
  margin: 5px;
  border: none;
`;
const GrpTitre = styled.div`
  display: flex;
  padding: 10px;
`;
const TitreCard = styled.h5`
  font-size: 14px;
  margin: 5px;
`;
const SpanChambre = styled.span`
  color: #0078d7;
  background: #d1eaff;
  font-size: 12px;
  font-weight: 500;
  padding: 0 5px 0 5px;
  border-radius: 19px;
`;
export const SpanStatue1 = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: #ffb800;
  background: #fff2cf;
  font-size: 12px;
  font-weight: 500;
  border-radius: 19px;
  padding: 5px 7px;
`;
export const SpanStatue3 = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: #ff1122;
  background: #ff11225a;
  font-size: 12px;
  font-weight: 500;
  border-radius: 19px;
  padding: 5px 7px;
`;
export const SpanStatue2 = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: #00fc91;
  background: #e6fff4;
  font-size: 12px;
  font-weight: 500;
  border-radius: 19px;
  padding: 5px 7px;
`;

const DetailsReservation = () => {
  const { id } = useParams();
  const idAsInt = parseInt(id, 10);
  const { data: reservation } = useGetReservationByIdQuery(idAsInt);
  console.log(reservation);
  let chambres;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (reservation) {
    chambres = reservation.chambres_associes;
  }
  const handleHistorique = async () => {
    navigate(
      `/${RELATION_CLIENT_PATH}/${DETAIL_CLIENT}/${reservation.voyageur_details.id}`
    );
    dispatch(setActiveTab(2));
  };
  const handleEdite = async () => {
    navigate(`/${UPDATE_RESERVATION_PATH}/${reservation.id}`);
  };
  return (
    <div style={styledRelationClientDetail.relationClient}>
      <ContainerDemandeClient className="container-fluid">
        <Heading1>Details Réservations</Heading1>
        {reservation && (
          <>
            <DivContain1>
              <GrpTitre>
                <Link to={`/${GESTION_RESERVATION_PATH}`}>
                  <p className="text-primary fs-6">Gestion réservations /</p>
                </Link>
                <TitreCard>{`Details Réservation ${reservation.voyageur_details.prenom} ${reservation.voyageur_details.nom}`}</TitreCard>
              </GrpTitre>
              <MesBouttons>
                <CustomButton
                  className="btn"
                  backgroundColor="#FFC857"
                  margin="10px 10px 10px 10px"
                  fontWeight="700"
                  borderRadius="5px"
                  border="none"
                  fontSize="14px"
                  color="black"
                  onClick={handleHistorique}>
                  Historique des reservations
                </CustomButton>
                <CustomButton
                  onClick={handleEdite}
                  color="black"
                  className="btn"
                  backgroundColor="#FFC857"
                  margin="10px 10px 10px 10px"
                  fontWeight="700"
                  borderRadius="5px"
                  border="none"
                  fontSize="14px">
                  Modifier
                </CustomButton>
              </MesBouttons>
            </DivContain1>
            <DivContain2>
              <Titre>{`Informations de la réservation n°${reservation.id} :  `}</Titre>
              <DivContain3>
                <DivContain4>
                  <DivTitlePeriode>
                    Période d'exclusivité de séjour
                  </DivTitlePeriode>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <CardPeriode>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> N° de la réservation :</b>{" "}
                          <span>{reservation.id}</span>{" "}
                        </p>{" "}
                      </div>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> Date de début du séjour :</b>{" "}
                          <span>{reservation.date_debut_sejour}</span>{" "}
                        </p>
                      </div>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> Date de fin du séjour :</b>{" "}
                          <span>{reservation.date_fin_sejour}</span>
                        </p>{" "}
                      </div>
                    </CardPeriode>
                  </div>
                  <DivTitlePeriode>
                    Détails et statut de la réservation{" "}
                  </DivTitlePeriode>
                  <hr />
                </DivContain4>
                <DivContain4>
                  <div className="d-flex justify-content-center">
                    <CardPeriode>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> Chambre : </b>
                          {chambres.map((chambre, index) => (
                            <SpanChambre key={index}>
                              {chambre.chambre}
                            </SpanChambre>
                          ))}
                        </p>{" "}
                      </div>
                      <div>
                        {" "}
                        <p className="d-flex flex-row gap-1">
                          {" "}
                          <b> Statut : </b>{" "}
                          <SpanStatue1>
                            {" "}
                            <GoDotFill /> {reservation.statut_reservation}
                          </SpanStatue1>{" "}
                        </p>
                      </div>
                    </CardPeriode>
                  </div>
                  <hr />
                  <DivTitlePeriode>Paiement </DivTitlePeriode>
                </DivContain4>
                <DivContain4>
                  <div className="d-flex justify-content-center">
                    <CardPeriode>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> Prix : </b>{" "}
                          <span>{reservation.montant_total}</span>{" "}
                        </p>{" "}
                      </div>
                      <div>
                        {" "}
                        <p>
                          {" "}
                          <b> Statut : </b>{" "}
                          <SpanStatue2>
                            {" "}
                            <GrValidate /> Payé
                          </SpanStatue2>{" "}
                        </p>
                      </div>
                    </CardPeriode>
                  </div>
                </DivContain4>
              </DivContain3>
            </DivContain2>
          </>
        )}
      </ContainerDemandeClient>
    </div>
  );
};

export default DetailsReservation;
