import React from "react";
import ReserveAndTotalCard from "./ReserveAndTotalCard";
import Onglets from "./Onglets";
import { format } from "date-fns";
import ReserveAndTotalCardTwo from "./ReserveAndTotalCardTwo";
import ReservationReseau from "./ReservationReseau";
import ReservationCentre from "./ReservationCentre";
import Badges from "./Badges";
import AutresInfo from "./AutresInfo";

function MenuClientDetail({ client, reservationsClient }) {
  let reservationsClientNombre;
  if (reservationsClient?.length === 0) {
    reservationsClientNombre = 0;
  } else {
    reservationsClientNombre = reservationsClient?.length || [];
  }
  const montantTotal = reservationsClient.reduce((total, reservation) => total + reservation.montant_total, 0);

  // Fonction pour formater la date
  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  return (
    <div>
      <div className="row">
        <Onglets formatDate={formatDate} client={client} reservationsClient={reservationsClient}
         style={{ overflow: "hidden" }} />
      </div>
      <div className="row " style={{ marginTop: "10px", }}>
        <div className="col-lg-6 px-2">
          <ReserveAndTotalCard number={reservationsClientNombre} title={"Réservations et Revenu générés"} numbers={`${montantTotal} FXOF`}/>
        </div>
        <div className="col-lg-6 px-1">
          <ReserveAndTotalCardTwo number={"4"} title={"Type de client"} seminaire={"01"} seule={8} />
        </div>
      </div>
        <div className="row " style={{ marginTop: "10px", }}>
          <div className="col-lg-6 px-2">
            <ReservationCentre number={reservationsClientNombre} title={"Réservations et Revenu de l'Hôtel"} numbers={`${montantTotal} FXOF`}/>
            <Badges />
            <AutresInfo />
          </div>
          <div className="col-lg-6 px-1">
          <ReservationReseau number={"4"} title={"Réseau sociaux"} seminaire={"01"} seule={8} />
        </div>
      </div>
    </div>
  );
}

export default MenuClientDetail;
