/* eslint-disable react/react-in-jsx-scope */
// import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import {
  LOGIN_PATH,
  PUBLIC_PATH,
  REGISTER_PATH,
  FORGETPASSWORD,
  RESETPASSWORD,
  FORMULAIRE_PRE_STAY_PATH,
  ACCUEIL_FORM, RESETMPD
} from "../navigationPaths";
import WithoutAuth from "layouts/WithoutAuth";
import ConnexionPage from "pages/connexion/ConnexionPage";
import ErrorPasswordPage from "pages/ErrorPasswordPage/ErrorPasswordPage";
import FormPreStay from "pages/Admin/GestionEnvoie/FormPreStay";
import AccueilForm from "pages/Admin/GestionEnvoie/AccueilForm";
import SplitShellFormPreStay from "layouts/AppShells/SplitShellFormPreStay";
import ConnexionFormResetMdp from "components/ConnexionFormResetMdp/ConnexionFormResetMdp";
import GestionEnvoi from "pages/Admin/GestionEnvoie/GestionEnvoi";
import ReinitialisationMdp from "components/ConnexionFormResetMdp/ReinitialisationMdp";

/*
|---------------------------------------------------------------
| Les routes publics
|---------------------------------------------------------------
| Toute page qui ne necessite pas une authentification doit etre ici
|
*/
export const publicRoutes = [
  {
    path: PUBLIC_PATH,
    errorElement: <ConnexionPage />,
    element: <WithoutAuth />,
    children: [
      {
        path: "",
        element: <ConnexionPage />,
      },
      {
        path: LOGIN_PATH,
        element: <ConnexionPage />,
      },
      {
        path: FORGETPASSWORD,
        element: <ErrorPasswordPage />,
      },
      {
        path: RESETMPD,
        element: <ReinitialisationMdp/>,
      },
      {
        path: RESETPASSWORD,
        element: <ConnexionFormResetMdp />,
      },
      {
        path: REGISTER_PATH,
        element: <p>Hello fromo register</p>,
      },
      {
        path: ":reservationId/hotel/:hotelId/voyage/:voyageurId",
        element: <GestionEnvoi />,
      },
      {
        path: ":reservationId/hotel/:hotelId/voyage/:voyageurId/formulaire",
        element: <FormPreStay />,
        //  children: [
        //     {
        //         path: "",
        //         element: <FormPreStay/>
        //     },
        //   {
        //     path: "apropos-nous",
        //     element: <AboutYouForm />,
        //   },
        //   {
        //     path: "services",
        //     element: <ServicePage />,
        //   },
        //   {
        //     path: "votre-sejour",
        //     element: <Sejour />,
        //   },
        //   {
        //     path: "validation",
        //     element: <Validation />,
        //   }
        //  ]
      },
      {
        path: "",
        element: <SplitShellFormPreStay />,
        children: [
          {
            path: ACCUEIL_FORM,
            element: <AccueilForm/>,
          },
          {
            path: ":slug/hotel/:id/formulaire",
            element: <FormPreStay />,
          }
        ]
      },
      {
        path: FORMULAIRE_PRE_STAY_PATH,
        element: <FormPreStay />,
      }
    ],
  },
];
