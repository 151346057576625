// import InfoPersonnel from "components/RelationClientDetail/InfoClient/InfoPersonnel";
import React from "react";
import styled from "styled-components";
import { BiArchive } from "react-icons/bi";

const InfoCardContain = styled.div`
  padding: ${(props) => props.padding};
   box-shadow: 1px 1px 5px #E2E3E4;
  margin-bottom: 10px;
  border-radius: 10.48px;
  border: 1px solid #DEE2E6;
  color: #000;
  font-size: size;
  cursor: pointer;
 
`;

const PrestaDate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items:center;
  /* padding-top: 10px; */
`;

const Date = styled.p`
  font-size: 14px;
  color: #667085;
  font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
`;

const CountryImage = styled.img`
  width: 30px; /* Taille réduite */
  height: 30px; /* Taille réduite */
  border-radius: 50%;
  margin-right: 15px; /* Espace entre l'image et le texte */
`;
const IconBorder = styled.div`
  width: 40px; /* Taille réduite */
  height: 40px; /* Taille réduite */
  border-radius: 10px;
  border: 1px solid #E7E9EB;
  text-align: center;
  margin-top: -5px;
`;
const EmotionText = styled.span`
font-size: 14px;
color:#101828;
font-family: Roboto;
font-weight: 500;
line-height: 20PX;
text-align: left;
`;


const CardElemenTraite = ({ padding, name, Pays, countryImage, preStayDate, inStayDate, size, func, emotionText, emotion }) => {
  return (
    <InfoCardContain onClick={func} padding={padding} size={size} className=" card-body row d-flex justify-content-between align-items-center">
      <div className="col-md-4 ">
        <InfoRow>
          <CountryImage src={countryImage} alt="Country flag" />
          <div>
            <p className="custom-paragraph" style={{ marginBottom: "0px", fontSize: "16px", fontFamily: "Roboto", fontWeight: 500, color: "#212529", width: "300px" }}>{name}</p>
            <p className="custom-paragraph" style={{ marginBottom: "5px", fontSize: "12px", color: "#6C757D" }}>{Pays}</p>
          </div>
        </InfoRow>
      </div>
      <div className="col-md-4 text-center">
       <div className="d-flex gap-2 align-items-center ">
        <img src={emotion} style={{ width: "30px" }}/>
        <EmotionText>{emotionText}</EmotionText>
       </div>
        <Date style={{ marginLeft: "25px" }}>{preStayDate}</Date>
      </div>
      <PrestaDate className="col-md-4 d-flex justify-content-between">
      <Date>{inStayDate}</Date>
      <IconBorder>
      <BiArchive style={{ width: "20px", height: "20px", marginLeft: "10px", margin: "10px auto" }} />
      </IconBorder>
      </PrestaDate>
    </InfoCardContain>
  );
};

export default CardElemenTraite;
