import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { Heading5 } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import React, { useEffect, useState } from "react";
import {
  Div,
  DivDotted,
  DivProfil,
  // DivReseau,
  SearchInput,
} from "components/AdminContain/NouveauVoyageur/NouveauVoyageurCss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GESTION_RESERVATION_PATH } from "routes/navigation/navigationPaths";
import { useDispatch, useSelector } from "react-redux";
import { addNewVoyageurReservation } from "redux/features/userSliceWithTokenValidation";
import { useFormContext } from "react-hook-form";
import FormInfoVoyageur from "components/AdminContain/NouveauVoyageur/FormInfoVoyageur";
import FormInfoReservation from "components/AdminContain/NouveauVoyageur/FormInfoReservation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import BreadCumbReservation from "./BreadCumbReservation";
import { Navig, TextBut } from "../../GestionVoyageur/GestionVoyageurCss";
// import {
//   useAddNewReservationMutation,
//   useAddNewVoyageurReservationMutation,
//   useGetReservationByIdQuery,
//   useGetReservationsQuery,
//   useGetRoomsQuery,
//   useGetVoyageursQuery,
//   useUpdateVoyageurReservationsMutation,
// } from "actions/apiSliceRTK";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import { useGetReservationByIdQuery, useGetReservationsQuery, useGetRoomsQuery } from "actions/reservation/query";
import { useAddNewReservationMutation, useUpdateVoyageurReservationsMutation } from "actions/reservation/mutation";
import { useAddNewVoyageurReservationMutation } from "actions/voyageur/mutation";
import { useGetVoyageursQuery } from "actions/voyageur/query";

export default function NouvelleReservation() {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, getValues, reset } = useFormContext();
  const { id } = useParams();
  const isUpdate = !!id;
  const idAsInt = parseInt(id, 10);
  const navigate = useNavigate();
  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  console.log({ userId });
  const { refetch } = useGetReservationsQuery(userId);
  // const reservations = reservationsData?.data || [];
  // const reservation = reservations.find((reservation) => idAsInt === reservation.id);
  const { data: reservation } = useGetReservationByIdQuery(idAsInt);
  const [buttonClicked, setButtonClicked] = useState(null);
  console.log({ reservation });

  const dispatch = useDispatch();
  const formData = getValues();
  // input recherche voyageur
  const [valueSearch, setValueSearch] = useState("");

  const [isCompletInfo, setIsCompletInfo] = useState(false);
  const [voyageur, setVoyageur] = useState(null);
  const completInfo = (voyageur) => {
    setVoyageur(voyageur);
    setIsCompletInfo(true);
    reset({
      selectedOption: voyageur.selectedOption,
      numero_passport: voyageur.numero_passport,
      prenom: voyageur.prenom,
      nom: voyageur.nom,
      email: voyageur.email,
      telephone: voyageur.telephone,
    });
  };
  const closeFC = () => {
    setIsCompletInfo(false);
    setVoyageur(null);
    reset({
      selectedOption: "",
      numero_passport: "",
      prenom: "",
      nom: "",
      email: "",
      telephone: "",
    });
  };

  // recupération chambre
  const [optionsChambres, setOptionsChambres] = useState([]);
  const { data: chambresData } = useGetRoomsQuery(userId);
  console.log("Chambres Data:", chambresData);
  useEffect(() => {
    if (chambresData) {
      setOptionsChambres(chambresData.data);
    }
  }, [chambresData]);

  const { data: voyageursData, refetch: refetchVoyageurs } =
    useGetVoyageursQuery(userId);
  const voyageurs = voyageursData?.data || [];
  const filteredVoyageurs = voyageurs.filter(
    (voyageur) =>
      voyageur.nom.toLowerCase().includes(valueSearch.toLowerCase()) ||
      voyageur.prenom.toLowerCase().includes(valueSearch.toLowerCase())
  );
  console.log(voyageur, "data voyageur");

  const [addNewVoyageurReservationMutation] =
    useAddNewVoyageurReservationMutation();
  const [addNewReservationMutation] = useAddNewReservationMutation();
  const [updateVoyageurReservationsMutation] =
    useUpdateVoyageurReservationsMutation();
  console.log({ voyageurs });
  const handleSubmitVoyageurReservation = async () => {
    setLoading(true);
    try {
      if (isUpdate && reservation) {
        // Logique de mise à jour
        const voyageurReservationData = {
          user: userId,
          identification: formData.selectedOption,
          numero_passport: formData.numero_passport,
          prenom: formData.prenom,
          nom: formData.nom,
          email: formData.email,
          telephone: formData.telephone,
          adresse: formData.adresse,
          date_naissance: formData.dateNaissance,
          genre: formData.genre,
          langue: formData.langue,
          chambres_associes: formData.chambres,
          nombre_person: formData.nombrePerson,
          nom_reservation: formData.nomReservation,
          date_debut_sejour: formData.dateDebut,
          date_fin_sejour: formData.dateFin,
          statut_reservation: formData.statut,
          origin: formData.origine,
          demandes_speciales: formData.demandeSpeciale,
          typedeClient: formData.typedeClient,
          motif_voyage: formData.typeDeVoyage,
          status_paiement: formData.paiement,
        };
        const dataReservation = await updateVoyageurReservationsMutation({
          id: reservation.id,
          voyageurReservationData,
        });
        console.log(
          "Voyageur et réservation mise à jour avec succès!",
          dataReservation
        );
        toast.success("Voyageur et réservation mise à jour avec succès !");
        // Redirection après la sauvegarde
        navigate(`/${GESTION_RESERVATION_PATH}`);
        refetch();
      } else {
        // Vérifier si un voyageur est sélectionné
        if (voyageur) {
          let voyageurId = null;
          // Si oui, vérifier s'il existe déjà dans la base de données
          const existingVoyageur = voyageurs.find((v) => v.id === voyageur.id);
          if (existingVoyageur) {
            voyageurId = existingVoyageur.id;
            const chambres = formData.chambres;
            const chambresAssoci = chambres.map((chambre) => ({
              chambre,
            }));
            console.log(formData.chambres);
            const reservationData = {
              user: userId,
              voyageur: voyageurId,
              chambres_associes: chambresAssoci,
              nom_reservation: formData.nomReservation,
              date_debut_sejour: formData.dateDebut,
              date_fin_sejour: formData.dateFin,
              nombre_person: formData.nombrePerson,
              statut_reservation: formData.statut,
              origin: formData.origine,
              demandes_speciales: formData.demandeSpeciale,
              typedeClient: formData.typedeClient,
              motif_voyage: formData.typeDeVoyage,
              status_paiement: formData.paiement,
            };
            const data = await addNewReservationMutation(reservationData);
            toast.success("Réservation ajouté avec succès !");
            console.log("Réservation ajoutée avec succés :", data);
          }
        } else {
          const voyageurReservationData = {
            user: userId,
            identification: formData.selectedOption,
            numero_passport: formData.numero_passport,
            prenom: formData.prenom,
            nom: formData.nom,
            email: formData.email,
            telephone: formData.telephone,
            adresse: formData.adresse,
            date_naissance: formData.dateNaissance,
            genre: formData.genre,
            langue: formData.langue,
            chambres_associes: formData.chambres,
            nombre_person: formData.nombrePerson,
            nom_reservation: formData.nomReservation,
            date_debut_sejour: formData.dateDebut,
            date_fin_sejour: formData.dateFin,
            statut_reservation: formData.statut,
            origin: formData.origine,
            demandes_speciales: formData.demandeSpeciale,
            type_client: formData.typedeClient,
            motif_voyage: formData.typeDeVoyage,
          };
          const data = await addNewVoyageurReservationMutation(
            voyageurReservationData
          );
          dispatch(addNewVoyageurReservation(voyageurReservationData));
          console.log("Voyageur et réservation ajoutés avec succès!", data);
          toast.success("Voyageur et réservation ajoutés avec succès !");
        }
        refetch();
        reset({
          selectedOption: "",
          numero_passport: "",
          prenom: "",
          nom: "",
          email: "",
          telephone: "",
          chambres: "",
          nomReservation: "",
          dateDebut: "",
          dateFin: "",
          statut: "",
        });
      }
      refetchVoyageurs();
    } catch (error) {
      // console.error("Erreur lors de l'ajout de la reservation :", error);
      // toast.error("Erreur lors de l'ajout de la reservation ");
    } finally {
      setLoading(false); // Désactive le chargement
    }
  };
  useEffect(() => {
    if (isUpdate && reservation) {
      const dateDebut = new Date(reservation.date_debut_sejour);
      const dateFin = new Date(reservation.date_fin_sejour);
      reset({
        selectedOption: "passport",
        numero_passport: reservation.voyageur_details.numero_passport,
        prenom: reservation.voyageur_details.prenom,
        nom: reservation.voyageur_details.nom,
        email: reservation.voyageur_details.email,
        telephone: reservation.voyageur_details.telephone,
        dateNaissance: reservation.voyageur_details.date_naissance,
        adresse: reservation.voyageur_details.adresse,
        langue: reservation.voyageur_details.langue,
        genre: reservation.voyageur_details.genre,
        dateDebut: dateDebut,
        dateFin: dateFin,
        chambres: reservation.chambres_associes.map(
          (chambre) => chambre.chambre
        ),
        nombrePerson: reservation.nombre_person,
        statut: reservation.statut_reservation,
        paiement: reservation.status_paiement,
        nomReservation: reservation.nom_reservation,
        source: "Wave",
        origine: reservation.origin,
        typedeClient: reservation.type_client,
        typeDeVoyage: reservation.motif_voyage,
        demandeSpeciale: reservation.demandes_speciales,
      });
    }
  }, [reservation, reset]);
  const handleButtonClick = (buttonType) => {
    setButtonClicked(buttonType);
  };
  const handleSubmitWithButtons = () => {
    if (buttonClicked === "saveAndExit") {
      handleSubmitVoyageurReservation();
      navigate(`/${GESTION_RESERVATION_PATH}`);
    } else if (buttonClicked === "saveOnly") {
      handleSubmitVoyageurReservation();
    }
  };

  return (
    <div style={styledRelationClientDetail.relationClient}>
      <div
        style={{
          padding: "20px 40px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <BreadCumbReservation />
        <ContainerDemandeClient
          className="container-fluid"
          style={{
            backgroundColor: "white",
            bordeRadius: "20px",
            padding: "40px",
          }}
        >
          <Navig>
            <p
              style={{
                color: "#000000",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <span
                style={{
                  color: "#008DF3",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                }}
              >
                {isUpdate && reservation ?
                   "Modification Réservation" :
                   "Nouvelle Reservation"}
              </span>
              <span>/</span>
            </p>
          </Navig>
          <form onSubmit={handleSubmit(handleSubmitWithButtons)}>
            <div className="row ">
              <div className="col-md-12 col-lg-7">
                <div className="row card border-1 border-thin pb-4 mb-5">
                  <Div className="col-5 col-lg-5">INFORMATIONS DU VOYAGEUR</Div>
                  <FormInfoVoyageur />
                </div>
                <div className="row card border-1 border-thin pb-5">
                  <Div className="col-6 col-lg-6">
                    INFORMATIONS DE LA RESERVATION
                  </Div>
                  <FormInfoReservation optionsChambres={optionsChambres} />
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                {isUpdate && reservation ? null : (
                  <div className="card border-1 border-thin p-5">
                    <div>
                      <Heading5 className="text-center">
                        SELECTIONNEZ UN VOYAGEUR
                      </Heading5>
                      <SearchInput
                        className="mt-4"
                        value={valueSearch}
                        onChange={(e) => setValueSearch(e.target.value)}
                        placeholder="Rechercher par nom"
                      />
                    </div>
                    {isCompletInfo ? (
                      <DivDotted
                        border="none"
                        background="#E0E0E0"
                        radius="5px"
                        className="mt-4 px-2 py-3 d-flex align-items-center justify-content-between border-1 border-thin"
                      >
                        <div className="d-flex">
                          <DivProfil className="d-flex align-items-center justify-content-center me-3">
                            {voyageur.prenom[0]}
                          </DivProfil>
                          <span style={{ fontStyle: "italic" }}>
                            {voyageur.prenom} {voyageur.nom}
                          </span>
                        </div>
                        <IoClose
                          style={{ fontSize: "24px", cursor: "pointer" }}
                          onClick={closeFC}
                        />
                      </DivDotted>
                    ) : (
                      <DivDotted
                        border="3px dotted #E0E0E0"
                        className="mt-4 ps-2 pb-3 d-flex flex-column"
                      >
                        {filteredVoyageurs.map((voyageur, index) =>
                          voyageur.archived ? null : (
                            <div
                              key={index}
                              style={{ cursor: "pointer" }}
                              className=" d-flex align-items-center pb-3"
                              onClick={() => completInfo(voyageur)}
                            >
                              <DivProfil className="d-flex align-items-center justify-content-center me-3">
                                {voyageur.prenom[0]}
                              </DivProfil>
                              <span style={{ fontStyle: "italic" }}>
                                {voyageur.prenom} {voyageur.nom}
                              </span>
                            </div>
                          )
                        )}
                      </DivDotted>
                    )}
                    {isCompletInfo ? (
                      <>
                        <DivDotted
                          border="3px dotted #E0E0E0"
                          className="mt-4 pb-3 d-flex align-items-center"
                        >
                          <span style={{ fontStyle: "blod" }}>
                            RECAPITULATIFS
                          </span>
                        </DivDotted>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Nom et prénom
                          </span>{" "}
                          <span>
                            {" "}
                            : {voyageur.nom} {voyageur.prenom}
                          </span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            E-mail
                          </span>{" "}
                          <span> : {voyageur.email}</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Numéro de téléphone
                          </span>{" "}
                          <span> : {voyageur.telephone}</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Titre
                          </span>{" "}
                          <span> : {voyageur.selectedOption}</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Numéro de passeport
                          </span>{" "}
                          <span> : {voyageur.numero_passport}</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Etat
                          </span>{" "}
                          <span> : Sénégal</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Ville
                          </span>{" "}
                          <span> : Dakar</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Adresse
                          </span>{" "}
                          <span> : Ouakam</span>
                        </p>
                        <p>
                          <span
                            style={{ textDecoration: "2px underline #ccc" }}
                            className="mt-2"
                          >
                            Profession
                          </span>{" "}
                          <span> : Designer</span>
                        </p>
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="row d-flex mt-5">
              <div className="d-flex gap-2">
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  disabled={loading}
                  type="submit"
                  name="saveAndExit"
                  onClick={() => handleButtonClick("saveAndExit")}
                  borderRadius="30px"
                  color="black"
                  border="1px solid #FFC857"
                  backgroundColor="#FFC857"
                  padding={"8px 16px"}
                  fontSize={"18px"}
                >
                  <TextBut>
                    {/* {loading ? "Enregistrement..." : ""} */}
                    {isUpdate && reservation ?
                       "Modifier et quitter" :
                       "Sauvegarder et quitter"}
                  </TextBut>
                </CustomButton>
                <CustomButton
                  className="d-flex align-items-center gap-1"
                  name="saveOnly"
                  onClick={() => handleButtonClick("saveOnly")}
                  type="submit"
                  borderRadius="30px"
                  color="#FFC857"
                  border="1px solid #FFC857"
                  backgroundColor="#fff"
                  padding={"8px 16px"}
                  fontSize={"18px"}
                >
                  <TextBut>
                    {loading ? "Enregistrement..." : ""}
                    {isUpdate && reservation ? "Modifier" : "Sauvegarder"}
                  </TextBut>
                </CustomButton>
                <Link to={`/${GESTION_RESERVATION_PATH}`}>
                  <CustomButton
                    className="d-flex align-items-center gap-1"
                    borderRadius="30px"
                    color="#FFC857"
                    border="1px solid #FFC857"
                    backgroundColor="#fff"
                    padding={"8px 16px"}
                    fontSize={"18px"}
                  >
                    <TextBut>Annuler</TextBut>
                  </CustomButton>
                </Link>
              </div>
            </div>
          </form>
          <ToastContainer className="mt-5" />
        </ContainerDemandeClient>
      </div>
    </div>
  );
}
