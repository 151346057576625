import { ApiMananger } from "actions/rtkquery";
import { reservationEndpoint, roomsEndpoint } from "routes/api/endpoints";

export const reservationQueries = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getReservations: builder.query({
      query: () => ({
        url: reservationEndpoint.addNewReservation(),
        method: "GET",
        // params: { user: userId },
      }),
    }),
    getReservationsForm: builder.query({
      query: (id) => ({
        url: reservationEndpoint.addNewReservation(":id", id),
        method: "GET",
      }),
    }),
    getReservationById: builder.query({
      query: (id) => ({
        url: reservationEndpoint.updateReservation.replace(":id", id),
        method: "GET",
      }),
    }),
    getRooms: builder.query({
      query: (userId) => ({
        url: roomsEndpoint.getRooms(userId),
        method: "GET",
        params: { user: userId },
      }),
    }),
  }),
});

export const {
  useGetRoomsQuery,
  useGetReservationsQuery,
  useGetReservationByIdQuery,
  useGetReservationsFormQuery,
} = reservationQueries;
