import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Checkbox from "./Checkbox";
import { IoCloseSharp } from "react-icons/io5";

const ModalInfo = () => {
  const options = [
    { value: "PMS", label: "PMS" },
    { value: "checkin", label: "Check in" },
    { value: "personalisé", label: "Personalisé" },
  ]
  const [selectedOptions, setSelectedOptions] = useState({
    first: "",
    second: "",
    third: "",
  });

  const handleCheckboxChange = (key) => (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: event.target.value,
    });
  };
  return (
    <div className="modal modal-lg" id="modalInfo" tabIndex="-1" aria-labelledby="modalInfoLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content p-5">
          <div className="modal-header d-flex justify-content-between" style={{ background: "#6C5B7B", color: "#FFF" }}>
            <h5 className="modal-title" id="modalInfoLabel">Provenance des informations</h5>
            <div type="button" className="" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px" }}>
              <IoCloseSharp/>
            </div>
          </div>
          <div className="modal-body">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col">
                <h6>Titre</h6>
              </div>
              <div className="col">
                <h6>Source</h6>
                <div className="d-flex flex-column">
                  <Checkbox options={options} name="source1"
                    value={selectedOptions.first}
                    onChange={handleCheckboxChange("first")} />
                </div>
              </div>
              <div className="col">
                <h6>Informations</h6>
                <div>
                  <p>Madame</p>
                  <p>Mme</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col">
                <h6>Titre</h6>
              </div>
              <div className="col">
                <h6>Source</h6>
                <div className="d-flex flex-column">
                  <Checkbox options={options}
                    value={selectedOptions.second} name="source2"
                    onChange={handleCheckboxChange("second")} />
                </div>
              </div>
              <div className="col">
                <h6>Informations</h6>
                <div>
                  <p>Remy</p>
                  <p>Remy</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col">
                <h6>Titre</h6>
              </div>
              <div className="col">
                <h6>Source</h6>
                <div className="d-flex flex-column">
                  <Checkbox options={options} name="source3"
                    value={selectedOptions.third}
                    onChange={handleCheckboxChange("third")} />
                </div>
              </div>
              <div className="col">
                <h6>Informations</h6>
                <div>
                  <p>Mendy</p>
                  <p>Mendy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInfo;
