import React from "react"
import styled from "styled-components";
import icon from "./../../../image/Icon.png"

function ReserveAndTotalCardTwo({ number, title, seminaire, seule }) {
  const Separator = styled.div`
  border-left: 1px solid #566577;
  height: 30px;
  margin: 0 10px;
  color: "#566577"
`;
  const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  // width: 200px;
  text-align: center;
`;
  const Number = styled.span`
  color: #0E233C; 
  font-size: 16px; 
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 8px; /* Adjust this value for spacing */
  width: 30px
`;

const Title = styled.span`
font-family: Roboto;
font-size: 16px;
font-weight: 500;
line-height: 18px;
color: #566577;
`;
  return (
    <div className="col border rounded d-flex flex-column  px-3 py-4 bg-white" style={{ height: "170px" }}>
      <TitleContainer>
        <Icon src={icon} alt="icon" />
        <Title>{title}</Title>
      </TitleContainer>
      <hr />
      <Card className="">
        <Number className="col-md-4">
          {number}
          <div>
            <span style={{ color: "#566577", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto" }}>En famille</span>
          </div>
        </Number>
        <Separator />
        <Number className="col-md-4">
        {seule}
          <div>
            <span style={{ color: "#566577", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto" }}>Seule</span>
          </div>
        </Number>
        <Separator />
        <Number className="col-md-4" >
        {seminaire}
          <div>
            <span style={{ color: "#566577", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto" }}>Séminaire</span>
          </div>
        </Number>
      </Card>
    </div>
  )
}

export default ReserveAndTotalCardTwo
