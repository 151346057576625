
import React from "react"
import { DivCentreInteret, Heading4 } from "../InfoClient/InfoClientCSS"
import { FaHeart } from "react-icons/fa"
// import styled from "styled-components";
// import icon from "./../../../image/Icon.png"

function ReservationCentre({ number, title, seminaire, seule }) {
    //   const Separator = styled.div`
    //   border-left: 1px solid #566577;
    //   height: 30px;
    //   margin: 0 10px;
    //   color: "#566577"
    // `;
    //   const Card = styled.div`
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   padding: 10px;
    //   // border: 1px solid #ccc;
    //   // border-radius: 8px;
    //   // width: 200px;
    //   text-align: center;
    // `;
    //   const Number = styled.span`
    //   font-weight: bold;
    //   font-size: 1.5rem;
    // `;
    // const TitleContainer = styled.div`
    //   display: flex;
    //   align-items: center;
    // `;

    // const Icon = styled.img`
    //   margin-right: 8px; /* Adjust this value for spacing */
    // `;

    // const Title = styled.span`
    //   color: #667085;
    //   font-weight: 400;
    //   font-size: 20px;
    // `;
    return (
        <div className="border rounded d-flex flex-column bg-blue-100  py-4 px-3 bg-white mb-2" style={{ height: "190px" }}>
            <div className="d-flex justify-content-between ">
                <Heading4>Centre d'intérêt</Heading4>
                <FaHeart style={{ color: "#E76F51", fontSize: "20px" }} />
            </div>
            <div className="d-flex flex-wrap" >
                <DivCentreInteret>Sport</DivCentreInteret>
                <DivCentreInteret>Voyager</DivCentreInteret>
                <DivCentreInteret>Pick nick</DivCentreInteret>
                <DivCentreInteret>Film</DivCentreInteret>
                <DivCentreInteret>Lecture</DivCentreInteret>
                <DivCentreInteret>Documentaire</DivCentreInteret>
                <DivCentreInteret>Football</DivCentreInteret>
                <DivCentreInteret>Promenade</DivCentreInteret>
                <DivCentreInteret>+</DivCentreInteret>
            </div>
        </div>

    )
}

export default ReservationCentre

