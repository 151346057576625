import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imageLogin1 from "image/ImageLogin1.png";
import imageLogin2 from "image/ImageLogin2.png";
import imageLogin3 from "image/ImageLogin3.png";
import { RESETPASSWORD } from "routes/navigation/navigationPaths";
import hotels from "image/azalai.png";
import { ImageLogin, LogoForm, Text,
    ParaLogoForm, ConnexionBody } from "components/ConnexionForm/LoginPageStyle"
import COLORS from "utils/Variables/color";
import { NavLink } from "react-router-dom";

const ReinitialisationMdp = () => {
    const navigate = useNavigate();
    const images = [imageLogin1, imageLogin2, imageLogin3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
      return () => clearInterval(interval);
    }, []);

    const handleSubmit = () => {
       navigate(RESETPASSWORD)
    };

    return (
        <ConnexionBody className="">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 d-md-block d-none m-0 py-0">
                <ImageLogin imageUrl={images[currentImageIndex]} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column gap-5 align-items-center justify-content-center p-5">
                    <div className="d-inline-flex align-items-center mt-1 gap-2">
                        <LogoForm src={hotels} alt="Hotels" className="" />
                        <ParaLogoForm>Azalai hotel</ParaLogoForm>
                    </div>
                    <div className="d-flex flex-column gap-4 p-5" style={{ padding: "10px" }}>
                        <div style={{ fontSize: "26px", fontWeight: "600" }}>
                            E-mail de reintialisation du mot de passe envoyé
                        </div>
                        <Text style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "16px", fontWeight: "400" }}>
                            La sécurité de notre compte est notre priorité !
                            Nous vous avons envoyé un lien sécurisé pour changer
                            votre mot de passe en toute sécurité et protéger votre compte.
                        </Text>
                    </div>
                    <button
                  type="submit"
                  className="w-100"
                  style={{
                    backgroundColor: `${COLORS.activeColor}`,
                    borderRadius: "5.333px",
                    padding: "12px 0px",
                    border: "none",
                    fontSize: "21.33px",
                    fontWeight: "500",
                    color: "#000",
                  }}
                  onClick={handleSubmit}
                >
                  Terminer
                </button>
                <NavLink style={{ fontSize: "22px", fontWeight: "600" }} to={"/auth/forgetPassword"}>Renvoyer un e-mail </NavLink>
                </div>
            </div>
        </ConnexionBody>
    );
}

export default ReinitialisationMdp;