import React from "react";
import styled from "styled-components";
import archive from "../../image/archive.png"

const InfoCardDetailsContain = styled.div`
  padding: ${(props) => props.padding};
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
//   border-bottom: 1px solid gray;
  cursor: pointer;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
`;

const CountryImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.p`
  margin-bottom: 5px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  color: #212529;
`;

const Country = styled.p`
  margin-bottom: 5px;
  font-size: 12px;
  color: #6C757D;
`;

const PrestaDate = styled.div`
  display: flex;
//   justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #667085;
  padding-right: 20%;
`;

const InfoCardDetails = ({ padding, name, Pays, countryImage, preStayDate, size, func }) => {
  return (
    <InfoCardDetailsContain onClick={func} padding={padding} style={{ fontSize: size, height: "60px" }}>
      <InfoSection>
        <CountryImage src={countryImage} alt="Country flag" />
        <InfoText>
          <Name>{name}</Name>
          <Country>{Pays}</Country>
        </InfoText>
      </InfoSection>
      <PrestaDate>{preStayDate}</PrestaDate>
      <button style={{ padding: "5px", borderRadius: "5px", border: " 1px solid #E7E9EB", color: "10px", backgroundColor: "white", marginRight: "40px" }}>
        {/* <span style={{ fontSize: "10px" }}>X</span> */}
        <img src={archive} />
        </button>
    </InfoCardDetailsContain>
  );
};

export default InfoCardDetails;
