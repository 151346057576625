// import React, { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
// import FreeServices from "components/GestionEnvoie/FreeServices";
// import PaidServices from "components/GestionEnvoie/PaidServices";
// import ServiceImageGrid from "components/GestionEnvoie/ServiceImageGrid";
// import { useFormContext } from "react-hook-form";
// import { useTranslation } from "react-i18next";

// const ServicePage = () => {
//   const { setValue } = useFormContext();
//   const [selectedService, setSelectedService] = useState(null);
//   const { t } = useTranslation();
//   const handleSelect = (serviceId) => {
//     console.log(`Selecting serviceId: ${serviceId}`);
//     setSelectedService(serviceId);
//     setValue("service", serviceId); // Store only the selected service ID
//   };

//   const handleDeselect = () => {
//     console.log(`Deselecting serviceId: ${selectedService}`);
//     setSelectedService(null);
//     setValue("service", null); // Clear the service field
//   };

//   // Commenting out quantity-related functions as requested
//   /*
//   const handleIncrease = (serviceId, amount) => {
//     console.log(`Increasing quantity for serviceId: ${serviceId} by ${amount}`);
//     setQuantities((prev) => {
//       const newQty = (prev[serviceId] || 1) + amount;
//       setValue("service", [{ serviceId, quantity: newQty }]);
//       console.log("Updated quantities:", { ...prev, [serviceId]: newQty });
//       return { ...prev, [serviceId]: newQty };
//     });
//   };

//   const handleDecrease = (serviceId, amount) => {
//     console.log(`Decreasing quantity for serviceId: ${serviceId} by ${amount}`);
//     setQuantities((prev) => {
//       const newQty = Math.max((prev[serviceId] || 1) + amount, 1);
//       setValue("service", [{ serviceId, quantity: newQty }]);
//       console.log("Updated quantities:", { ...prev, [serviceId]: newQty });
//       return { ...prev, [serviceId]: newQty };
//     });
//   };
//   */

//   useEffect(() => {
//     console.log("Selected service:", selectedService);
//   }, [selectedService]);

//   return (
//     <div
//       className="container my-5 py-5 d-flex flex-column align-items-center justify-content-center"
//       style={{ paddingLeft: "8rem" }}>
//       <h3 className="text-center mb-4">
//         {t("AVEZ-VOUS BESOIN DE SERVICES PARTICULIERS ?")}
//       </h3>
//       <h4 className="text-center mb-5">NECTAR BY MAISON MÈRE</h4>

//       <ServiceImageGrid />

//       <p className="text-center mb-5">
//         {t("Découvrez notre")}{" "}
//         <strong>{t("refuge gastronomique effervescent et décomplexé")}</strong>
//         ...
//       </p>

//       <div className="text-center mb-5">
//         <Button variant="link">{t("Réserver")}</Button>
//       </div>

//       <FreeServices />

//       <PaidServices
//         selectedServices={selectedService ? [selectedService] : []}
//         quantities={{}} // Empty quantities since we are not using them
//         handleSelect={handleSelect}
//         handleDeselect={handleDeselect}
//         handleIncrease={() => {}} // No-op functions
//         handleDecrease={() => {}} // No-op functions
//       />
//     </div>
//   );
// };

// export default ServicePage;

import React from "react";
import styled from "styled-components";
import Bouton from "components/FormulairePreStay/Bouton";
import GroupImg from "image/Groupe.png";
import ServiceImg from "image/service.jpeg";
import ServiceGratuit from "components/FormulairePreStay/ServiceGratuit";
import ServicePayant from "components/FormulairePreStay/ServicePayant";
import Text from "components/FormulairePreStay/Text";
const Wrapper = styled.div`
    align-items: center;
    --bs-gutter-x: 2.5rem !important;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #D26129;
    padding: 20px;
    gap: 20px;
    box-sizing: border-box;
`;
const ImageContainer = styled.div`
    width: 50px;
    height: auto;
    img {
        width: 40%;
        margin-top: 10px;
        height: auto;
    }
`;
const Texte = styled.p`
 font-family: "Quicksand", sans-serif;
 font-size: 20px;
 font-weight: 300;
 text-align: center;
 color: white;
  @media(max-width: 767px){
    font-size: 14px;
 }
`
const Img = styled.img`
height: 39rem;
    @media(min-width: 768px) and (max-width: 773px){
    height: 55.5rem;
    }
    @media(min-width: 774px) and (max-width: 802px){
    height: 53.5rem;
    }
    @media(min-width: 803px) and (max-width: 884px){
    height: 52rem;
    }
    @media(min-width: 885px) and (max-width: 930px){
    height: 50rem;
    }
    @media(min-width: 931px) and (max-width: 965px){
    height: 44.5rem;
    }
    @media(min-width: 966px) and (max-width: 1020px){
    height: 43rem;
    }
    @media(min-width: 1021px) and (max-width: 1024px){
    height: 41rem;
    }
`
const Titre = styled.p`
font-size: 75px;
font-family: "Quicksand", sans-serif;
font-weight: 700;
color: white;
text-align: center;
margin: 0;
 @media(max-width: 767px){
    font-size: 50px;
 }
`

const Service = ({ handleCancel, handleClick }) => {
    return (
        <>
            <p style={{ textAlign: "center", paddingTop: "20px" }}><Text content={"Avez-vous besoin de services particuliers ?"} /></p>
            <Wrapper className="row">
                <ServiceContainer className="col-md-6">
                    <ImageContainer>
                        <img src={GroupImg} alt="Group" />
                    </ImageContainer>
                    <Titre>NECTAR BY Azalai Hotel</Titre>
                    <Texte>
                        Découvrez notre refuge gastronomique effervescent et décomplexé,
                        où l'expertise se marie à la créativité, offrant une expérience polysensorielle
                        rebelle et contemporaine, créée par un collectif de passionnés. Plongez dans
                        l’univers du menu Pollen, une balade en 3 actes à 49€. Pour nos fins gourmets,
                        optez pour le menu Apis, une symphonie en 5 actes à 69€, où les ingrédients de
                        saison sont les notes principales.
                    </Texte>
                    <Bouton text="Commander" color="black" width="180px" height="40px" bgColor="white" hoverColor="none" fontSize="11px" />
                </ServiceContainer>
                <div className="col-md-6 p-0">
                    <div>
                        <Img src={ServiceImg} alt="Service" className="w-100" />
                    </div>
                </div>
            </Wrapper>
            <ServiceGratuit />
            <ServicePayant handleCancel={handleCancel} handleClick={handleClick} />
        </>
    );
};

export default Service;
