import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./form.css";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import hotels from "../../image/azalai.png";
import { ImageLogin, LogoForm, ParaLogoForm, Text, ConnexionBody } from "components/ConnexionForm/LoginPageStyle"
import "./form.css";
import imageLogin1 from "image/ImageLogin1.png";
import imageLogin2 from "image/ImageLogin2.png";
import imageLogin3 from "image/ImageLogin3.png";
import { MdOutlineAlternateEmail } from "react-icons/md";
import COLORS from "utils/Variables/color";
import { FaArrowLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "actions/authentification/mutation"
import { RESETMPD } from "routes/navigation/navigationPaths";

const ErrorPasswordForm = () => {
  const images = [imageLogin1, imageLogin2, imageLogin3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  }
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const emailValue = watch("email");
const isEmailValid = emailValue && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
  const [handleForgetPw, { isLoading }] = useForgotPasswordMutation();
  const handleSoumet = handleSubmit((data) => {
    console.log({ data });
    handleForgetPw(data);
    toast.success("Veuillez vérifier votre e-mail. Un code vous a été envoyé.");
    setTimeout(() => {
      navigate(RESETMPD);
    }, 3000);
  })

  return (
    <>
    <ToastContainer className={"mt-5"}/>
      <ConnexionBody className="overflow-x-hidden">
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 d-md-block d-none m-0 py-0">
          <ImageLogin imageUrl={images[currentImageIndex]} />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6 d-flex flex-column gap-4 align-items-center justify-content-center">
          <div className="d-inline-flex align-items-center mt-1 gap-2">
            <LogoForm src={hotels} alt="Hotels" className="" />
            <ParaLogoForm>Azalai hotel</ParaLogoForm>
          </div>
            <form
              className="auth-forms MotDePasse p-4 mx-0 mx-lg-5"
              // autoComplete="off"
              onSubmit={handleSoumet}
            >
              <div
                className="d-flex flex-column gap-2 justify-content-start"
                style={{ padding: " 20px" }}
              >
                 <div>
              <p style={{ fontSize: "26px", fontWeight: "600" }}>Mot de passe oublié</p>
          </div>
                <div>
                  <Text
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Entrez votre adresse e-mail ci-dessous et nous vous envoyons
                    des instructions sur la façon de modifier votre mot de passe.
                  </Text>
                </div>
                <label className="fw-bold pb-1">E-mail</label>
              <div className="input-group mb-3 border border-black rounded">
                <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                  <MdOutlineAlternateEmail />
                </span>
                <input
                  type="email"
                  className="form-control py-3 bg-transparent border border-0"
                  placeholder="Tapez votre e-mail"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  {...register("email", {
                    required: "L'e-mail est requis",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Veuillez entrer une adresse e-mail valide",
                    },
                  })}
                />
              </div>
              {errors.email && <div className="alert alert-danger gfa-alert-danger" role="alert">
                  Veuillez fournir votre adresse mail
                </div>}
                <div className="row align-items-center">
                <div className="col-md-2 p-2">
                <button
                    type="button"
                    className="w-100"
                    style={{
                      backgroundColor: `${COLORS.activeColor}`,
                      borderRadius: "5.333px",
                      padding: "12px 0px",
                      border: "none",
                      fontSize: "21.33px",
                      fontWeight: "500",
                      color: "#000",
                    }} onClick={handleClick}
                  >
                    <FaArrowLeft/>
                  </button>
                </div>
                <div className="col-md-10 p-2">
                <button
                    type="submit"
                    className="w-100"
                    style={{
                    backgroundColor: isEmailValid ? COLORS.activeColor : COLORS.inActiveColor,
                      borderRadius: "5.333px",
                      padding: "12px 0px",
                      border: "none",
                      fontSize: "21.33px",
                      fontWeight: "500",
                      color: "#000",
                    }}
                    disabled={!isEmailValid || isLoading}
                  >
                    <span>
                      {isLoading ? "Chargement..." : "Envoyer"}
                    </span>
                  </button>
                </div>
                </div>
              </div>
            </form>
            <div>
              <div
                style={{
                  color: "#FFD964",
                }}
                className="text-center"
              >
              </div>
            </div>
          </div>
        </div>
      </ConnexionBody>
    </>
  );
};

export default ErrorPasswordForm;
