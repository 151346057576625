import React from "react";
import { FiCalendar } from "react-icons/fi";
// import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
// import { RxDotFilled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { DETAIL_RESERVATION_PATH } from "routes/navigation/navigationPaths";
import SMS from "../../../image/SMS.png"
import chevronRight from "../../../image/chevron-right.png"

function HistoriqueConversation({ montant, debut, fin, number, statut }) {
    const navigate = useNavigate();
    const handleDetails = async (reservationId) => {
        navigate(`/${DETAIL_RESERVATION_PATH}/${reservationId}`);
    };
    return (
        <div className="card-body py-2 pe-3 row rounded d-flex align-items-center justify-content-center" onClick={() => handleDetails(number)} style={{
            marginTop: "5px",
            boxShadow: "1px 1px 3px gray",
            margin: "15px",
            padding: "0px",
            backgroundColor: "#fff",
            fontSize: "12px",
            cursor: "pointer"
        }}>
            <div className="col-4 fw-medium p-3">
                <div className="d-flex align-items-center m-0 gap-2">
                    <img src={SMS} className="" />
                    <p className="m-0" style={{
                        fontSize: "14px", marginLeft: "47px", color: "#0E233C", fontFamily: "Roboto",
                        fontWeight: 400
                    }}>SMS</p>
                </div>

            </div>
            <div className="col-3 fw-medium">
                <p className=" m-0" style={{
                    fontSize: "16px", color: "#0E233C", fontFamily: "Roboto",
                    fontWeight: 400
                }}>Date d’envoi</p>
                <p className="m-0" style={{
                    fontSize: "14px", color: "#0E233C", fontFamily: "Roboto",
                    fontWeight: 400
                }}><FiCalendar /> {debut}</p>
            </div>
            <div className="col-3 fw-medium">
                <p className="m-0" style={{
                    fontSize: "16px", color: "#0E233C", fontFamily: "Roboto",
                    fontWeight: 400
                }}>Pre-Stay</p>
                {/* <p className="m-0"><FiCalendar /> {fin}</p> */}
            </div>
            <div className="col-2 d-flex align-items-center">
                <span className="status rounded-pill pe-1 d-flex flex-row">
                <img src={chevronRight} style={{ marginLeft: "70px" }}/>
                </span>
            </div>
        </div>
    )
}

export default HistoriqueConversation