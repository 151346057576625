import React from "react";
import ATraiter from "./ATraiter";
import Conversation from "./Conversation";
import HistoriqueReservation from "./HistoriqueReservation";
// import Liens from "./Liens";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "redux/features/SplitShellSlice";
import "./acive.css"
import HistoriqueConversation from "./HistoriqueConversation";
import { Link } from "react-router-dom";

function Onglets({ client, reservationsClient, formatDate }) {
  const activeTab = useSelector((state) => state?.persist?.sidebarWidth.activeTab);
  const dispatch = useDispatch();

  return (
    <div className="px-2" style={{ overflow: "hidden", }}>
      <div className="border px-3 pt-4" style={{ backgroundColor: "white", height: "73px", borderRadius: "10px" }}>
        <ul
          className="detail-client-tabs d-flex align-items-center gap-5 list-unstyled"
          style={{ fontSize: "16px", color: "#566577", fontFamily: "Roboto", fontWeight: 400 }}
        >
          <li
            className={`detail-client-tabs-item ${activeTab === 0 ? "active-tab" : ""}`}
            style={{ ...styledRelationClientDetail.detailClientTabsLi }}
            onClick={() => dispatch(setActiveTab(0))}
          >
            À traiter
          </li>
          <li
            className={`detail-client-tabs-item ${activeTab === 1 ? "active-tab" : ""}`}
            style={{ ...styledRelationClientDetail.detailClientTabsLi }}
            onClick={() => dispatch(setActiveTab(1))}
          >
            Conversation
          </li>
          <li
            className={`detail-client-tabs-item ${activeTab === 3 ? "active-tab" : ""}`}
            style={{ ...styledRelationClientDetail.detailClientTabsLi }}
            onClick={() => dispatch(setActiveTab(3))}
          >
            Historique des conversation
          </li>
          <li
            className={`detail-client-tabs-item ${activeTab === 2 ? "active-tab" : ""}`}
            style={{ ...styledRelationClientDetail.detailClientTabsLi }}
            onClick={() => dispatch(setActiveTab(2))}
          >
            Historique des réservations
          </li>
        </ul>
      </div>
      <div className=" mt-3">
      {activeTab === 0 && <Link className="atraiter" style={{ fontSize: "13px" }} >À traiter <span className="barre">/</span> </Link>}
      {activeTab === 1 && <Link className="atraiter" style={{ fontSize: "13px" }} >Coversation <span className="barre">/</span> </Link>}
      {activeTab === 2 && <Link className="atraiter" style={{ fontSize: "13px" }} >Historique des réservations<span className="barre">/</span> </Link>}
      {activeTab === 3 && <Link className="atraiter" style={{ fontSize: "13px" }} >Historique des conversations<span className="barre">/</span> </Link>}
      </div>
      <div
        className="contentTabs rounded-3 mt-3  border "
        style={{ ...styledRelationClientDetail.contentTabs, overflow: "hidden" }}
      >
        {activeTab === 0 && <ATraiter formatDate={formatDate} client={client} />}
        {activeTab === 1 && <Conversation client={client} />}
        {activeTab === 2 && <HistoriqueConversation />}
        {activeTab === 3 && <HistoriqueReservation formatDate={formatDate} reservationsClient={reservationsClient} />}
      </div>
    </div>
  );
}

export default Onglets;

