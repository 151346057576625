const COLORS = {
  primary: "#99523D",
  secondary: "#3498DB",
  accent: "#F39C12",
  text: "#333333",
  background: "#F5F5F5",
  secondaryBackground: "#6C5B7B",
  tertiaryBackground: "#EAECF0",
  primaryBorderColor: "#AEAEAE",
  activeColor: "#FFC857",
  inActiveColor: "#FFC85780",
  backgroundIcone: "#F3F4F5",
  blueColor: "#0165E1",
};

export default COLORS;
