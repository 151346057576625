import React from "react"
import HotelChambre from "image/image/chambre.png"
import { HiArrowLongRight } from "react-icons/hi2";
import Line from "image/image/line.png"
import { useNavigate } from "react-router-dom";
import Button from "components/FormulairePreStay/Bouton";
import { useTranslation } from "react-i18next";
import { ContainerAccueil, ContainerText, Transform, Contain,
    Message, ContainImage, Image, Image2, TextUtils, ContainTextImage
 } from "components/FormulairePreStay/StyledFormPreStay";

function AccueilForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/:slug/hotel/:id/formulaire");
    };
  return (
    <Contain>
        <ContainerAccueil>
            <ContainerText>
                <Transform>
                {t("Transformons votre sejour chez Azalai Hotel Dakar en moments inoubliables")}
                </Transform>
                <ContainTextImage>
                <Image2 src={Line}/>
                <div>
                    <Message>
                    Cher Monsieur Mouhamet, Merci d'avoir choisi Azalai Hôtel où nous aurons
                    le plaisir de vous accueillir dans 7 jours.
                    </Message>
                <Message>
                Pour faire de votre séjour une
                expérience unique et personnalisée, nous avons préparé un questionnaire afin
                de préparer au mieux votre séjour à la Maison
                </Message>
                </div>
                </ContainTextImage>
                <Button onClick={handleClick} name="Remplir le questionnaire" icon={<HiArrowLongRight />}/>
                <div>
                    <TextUtils>
                    Utilisez le lien suivant si le bouton ci-dessus n'apparaît pas : <br />
                    <a href="https://www.azalai.com/azalai-hotel-dakar" target="_blank" rel="noopener noreferrer">https://www.azalai.com/azalai-hotel-dakar</a>
                    </TextUtils>
                </div>
            </ContainerText>
            <ContainImage>
                <Image src={HotelChambre}/>
            </ContainImage>
        </ContainerAccueil>
    </Contain>
  )
}

export default AccueilForm