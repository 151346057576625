import React, { useState } from "react";
import styled from "styled-components";
import Titre from "components/FormulairePreStay/Titre";
import Text from "components/FormulairePreStay/Text";
import Bouton from "components/FormulairePreStay/Bouton";
import { useSelector } from "react-redux";
import { useGetServicesForFormularQuery } from "actions/service/query";
import { CheckboxContainer } from "pages/Admin/GestionEnvoie/TabsFormPreStay/StyledTabsForm";
import { useFormContext } from "react-hook-form";

// Styled Components
const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 25px;
  @media (max-width: 767px) {
    display: block;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LargeIcon = styled.img`
  width: 15%;
  border-radius: 50%;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: end;
`;

const PriceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

// ServicePayant Component
const ServicePayant = ({ handleCancel, handleClick }) => {
  const userId = useSelector((state) => state?.persist?.auth?.user_id);
  const { data: services } = useGetServicesForFormularQuery(userId);
  const [selectedService, setSelectedService] = useState(null);

  const { register, setValue } = useFormContext();

  const handleSelect = (serviceId) => {
    setSelectedService(serviceId);
    console.log(serviceId);
    setValue("service", serviceId); // Store selected service ID
  };

  const handleDeselect = () => {
    setSelectedService(null);
    setValue("service", null); // Clear service selection
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Container className="col-lg-6 col-sm-12 col-md-12 col-xl-6">
        <Titre
          text="Services Payants"
          style={{
            color: "#D26129",
            fontFamily: "Roboto",
            fontSize: "25.28px",
            fontWeight: "600",
          }}
        />
        {services?.data?.map((service) => (
          <Content key={service?.id}>
            <Item>
              <LargeIcon src={service?.image_url} alt="Service" />
              <Description>
                <Text
                  content={
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                      {service.title}
                    </span>
                  }
                />
                <Text
                  content={
                    <span style={{ fontSize: "14px" }}>
                      {service.description}
                    </span>
                  }
                />
              </Description>
            </Item>
            <PriceContainer>
              <PriceText>
                <Text
                  content={
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {service?.prix_unitaire}
                      <span style={{ fontWeight: "normal", color: "gray" }}>
                        {" "}
                        FCFA{" "}
                      </span>
                    </span>
                  }
                />
                <Text
                  content={
                    <span style={{ fontSize: "15px" }}>par personne</span>
                  }
                />
              </PriceText>
              <CheckboxContainer>
                <div className="round">
                  <input
                    {...register("servicesPayants")}
                    type="checkbox"
                    id={`checkbox-${service?.title}`}
                    checked={selectedService === service.id}
                    onChange={(e) => {
                      e.target.checked
                        ? handleSelect(service.id)
                        : handleDeselect();
                    }}
                  />
                  <label htmlFor={`checkbox-${service?.title}`}></label>
                </div>
              </CheckboxContainer>
            </PriceContainer>
          </Content>
        ))}
      </Container>
      <ButtonContainer>
        <Bouton onClick={handleCancel} name={"Précédent"} />
        <Bouton onClick={handleClick} name={"Suivant"} />
      </ButtonContainer>
    </div>
  );
};

export default ServicePayant;
