import React from "react";
import NouveauVoyageur from "components/AdminContain/NouveauVoyageur/NouveauVoyageur";
import VoyageurProvider from "components/AdminContain/NouveauVoyageur/Provider/VoyageurProvider";

const NouveauVoyageurPage = ({ hotel }) => {
  return (
    <VoyageurProvider>
      <NouveauVoyageur hotel={hotel} />
    </VoyageurProvider>
  );
};

export default NouveauVoyageurPage;
