import styled from "styled-components";
import React from "react";
<style>@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')</style>;
export const DivBreadCrumb = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 40px;
    padding: 10px 0px;
    background-color: #f0f0f0 !important;
    position: fixed;
    width: calc(100vw - 309px);
    // width: ${({ width }) => width};
    z-index: 1;
    padding-left: 40px;
    overflow: hidden;
`;
export const Card = styled.div`
    border-radius: 10px;
    overflow-y: auto;
    cursor: grab;
    height: calc(100vh - 150px);
    user-select: none;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
    }
`;
export const CardBody = styled.div`
    // height: fit-content;
`;
export const ButtonClose = styled.div`
    color: #fff;
`;
export const Boutton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    background: #ffc857;
`;
export const Heading1 = styled.h1`
    font-size: 26px;
    font-family: Roboto;
    color: #ffffff;
    text-decoration: underline;
    text-align: center;
    padding-top: 50px;
    font-weight: 400;
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;
export const Heading2 = styled.h2`
    font-size: 22px;
    font-family: Roboto;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
export const Heading3 = styled.h3`
    font-size: 20px;
    font-family: Roboto;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;
export const Heading4 = styled.h4`
    font-size: 18px;
    font-family: Roboto;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
export const Heading5 = styled.h5`
  font-size: 16px;
  font-family: Roboto;
  @media (max-width: 768px) {
    font-size: &'px;
  }
`;
export const Paragraph = styled.span`
    font-size: 14px;
    font-family: Roboto;
`;
export const Nom = styled.div`
    width: 140px;
`;
export const DivCentreInteret = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80px;
    height: 30px;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    margin: 0 7px 7px 0;
`;
export const DivSignePlus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffc857;
    width: 15px;
    height: 15px;
    border: 1px solid #ffc857;
    border-radius: 50%;
`;
export const Img = styled.img`
    width: 30px;
    height: 20px;
    border-radius: 3px;
    
    @media (max-width: 768px){
    width: 20px;
    height: 12px;
    }
`;
