import React, { useState, useEffect, useRef } from "react";
import { IoVideocamOutline } from "react-icons/io5";
import { FaRegQuestionCircle } from "react-icons/fa";
import { SlEarphonesAlt } from "react-icons/sl";
import { IoMdArrowDropdown } from "react-icons/io";
import { DropdownMenuAssistance, DropdownContainer, MenuItem, IconStyle } from "./StyleNavbarAndElement";

const AssistanceDropdown = () => {
  const [isAssistanceDropdownVisible, setIsAssistanceDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); // Utiliser useRef pour le dropdown

  const toggleAssistanceDropdown = () => {
    setIsAssistanceDropdownVisible(!isAssistanceDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsAssistanceDropdownVisible(false); // Fermer le dropdown si on clique à l'extérieur
    }
  };

  // Ajout d'un listener pour les clics à l'extérieur du dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <div
        onClick={toggleAssistanceDropdown}
        style={{
          marginLeft: 10,
          marginRight: 15,
          color: "#000000",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          transition: "color 0.3s",
          position: "relative",
        }}
        aria-haspopup="true"
        aria-expanded={isAssistanceDropdownVisible}
        role="button"
      >
        Assistance <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
      </div>
      {isAssistanceDropdownVisible && (
        <DropdownMenuAssistance>
          <MenuItem>
            <IoVideocamOutline style={IconStyle} /> Vidéos tutoriels
          </MenuItem>
          <MenuItem>
            <FaRegQuestionCircle style={IconStyle} /> Centre d'aide
          </MenuItem>
          <MenuItem>
            <SlEarphonesAlt style={IconStyle} /> Contacter l'assistance
          </MenuItem>
        </DropdownMenuAssistance>
      )}
    </DropdownContainer>
  );
};

export default AssistanceDropdown;
