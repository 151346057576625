import styled from "styled-components";

export const Input = styled.input`
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
`;

export const Select = styled.select`
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
`;
export const InputDate = styled.input`
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 0px;
`;

export const Label = styled.label`
  width: 50%;
  text-align: end;
`;

export const MiniForm = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
`;
export const ServiceGratuit = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
export const ServiceGratuitWithImage = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 5px;
  width: 50%;
`;
export const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;
export const ServiceText = styled.div`
  font-size: 18px;
`;
export const ServiceIcon = styled.p`
  padding: 0;
  margin: 0;
`;
export const ServiceImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 50%;
`;
export const CheckboxContainer = styled.div`
  .round {
    position: relative;
  }

  .round label {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #00a0ff;
    border-color: #00a0ff;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

export const InputCheckbox = styled.input`
  width: 44px;
  height: 44px;
  background-color: transparent;
`;
