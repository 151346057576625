import React from "react";
import { SearchInput, InputVoyage, SearchIcone } from "components/HeaderListe/Navbarcss";
import SIZE from "utils/Variables/size";
import { useNavigate } from "react-router-dom";
import { TEXTESPREPARES, TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";
import { SidebarItems } from "pages/Admin/StyledAdmin";
import { FaSearch } from "react-icons/fa";


function HeaderEmailTextPrep({ valueSearch, setValueSearch }) {
    const navigate = useNavigate();
    const switchEmail = () => {
        navigate(`/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`);
    }
    return (
        <div className="row p-0 py-2 align-items-center">
            <div className="col-md-6">
            <SearchInput style={{ border: "1px solid #78828C21" }}>
               <SearchIcone> <FaSearch /></SearchIcone>
                <InputVoyage value={valueSearch} onChange={(e) => setValueSearch(e.target.value)}
                 placeholder="Rechercher par nom" style={{ color: "#55595C", fontFamily: "Roboto", fontWeight: "300", }}/>
            </SearchInput>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center pt-2">
                <SidebarItems
                    className="btn btn-warning"
                    onClick={switchEmail}
                    style={{ fontWeight: "500", fontSize: `${SIZE.large}`, borderRadius: "20px" }}
                >
                    Nouveau texte préparé
                </SidebarItems>
            </div>

        </div>
    )
}

export default HeaderEmailTextPrep