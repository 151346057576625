import React from "react";
import { Cake, ContCard, Heading4, Text } from "../InfoClient/InfoClientCSS";
import cake from "image/cake2-fill.svg"


function AutresInfo () {
    return (
        <ContCard className=" border rounded d-flex flex-column px-3 py-4 bg-white mb-2">
            <Heading4>Autres informations</Heading4>
            <div className="d-flex align-items-center gap-2 mt-4 mx-2">
                <Cake src={cake} alt="" />
                <Text>Anniversaire durant le séjour</Text>
            </div>
        </ContCard>
    )
}
export default AutresInfo;