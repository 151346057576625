import React from "react"
import styled from "styled-components";
import "../CalendrierduClient/calendrier.css"

function ReserveAndTotalCard({ number, title, numbers }) {
  const Separator = styled.div`
  border-left: 1px solid #566577;
  height: 30px;
  margin: 0 10px;
  color: "#566577"
`;
  const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  // width: 200px;
  text-align: center;
`;
  const Number = styled.span`
  font-weight: bold;
  font-size: 16px;
`;
  return (
    <div className="col border rounded d-flex flex-column  px-3 py-4 bg-white" style={{ height: "170px" }}>
      <span style={{ color: "#566577", fontWeight: "500", fontSize: "16px", fontFamily: "Roboto" }}>{title}</span>
      <hr className="margin-negative" />
      <Card className="">
        <Number className="col-md-6" style={{ color: "#0E233C", fontSize: "16px", fontWeight: "500", fontFamily: "Roboto" }}>
          {number}
          <div>
            <span style={{ color: "#566577", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto" }}>Réservations</span>
          </div>
        </Number>
        <Separator />
        <Number className="col-md-6" style={{ color: "#0E233C", fontSize: "16px", fontWeight: "500", fontFamily: "Roboto" }}>
        {numbers}
          <div>
            <span style={{ color: "#566577", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto" }}>Revenu total</span>
          </div>
        </Number>
      </Card>
    </div>
  )
}

export default ReserveAndTotalCard
