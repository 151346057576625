import React from "react"
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { DivSignePlus, Paragraph } from "./InfoClientCSS";
import { MdOutlineStickyNote2 } from "react-icons/md";

export default function ListeTaches({ taches }) {
  return (
    <div className="card shadow p-3" style={{ fontFamily: "Roboto" }}>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <DivSignePlus className="me-2">+</DivSignePlus>
          <span style={{ color: "#FFC857", fontSize: "14px", fontWeight: 400 }}>Ajouter une note</span>
        </div>
        <MdOutlineStickyNote2 style={{ fontSize: "18px" }} />
      </div>
      {taches.map((tache, index) => (
        <div key={index} className="d-flex flex-column">
          <Paragraph className="d-flex justify-content-between" style={{ color: "#6C5B7B", fontWeight: "400" }}>
            {tache.date}
            <div>
              <BiEditAlt style={{ color: "blue", fontSize: "18px" }} />
              <RiDeleteBin6Line style={{ color: "red", fontSize: "18px", marginLeft: "3px" }} />
            </div>
          </Paragraph>
          <Paragraph className="mt-2">{tache.title}</Paragraph>
          {index !== taches.length - 1 && <hr />}
        </div>
      ))}
    </div>
  )
}

