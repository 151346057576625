import React, { useState, useEffect } from "react";
import { NavLink, /* useNavigate */ } from "react-router-dom";
import imageLogin1 from "image/ImageLogin1.png";
import imageLogin2 from "image/ImageLogin2.png";
import imageLogin3 from "image/ImageLogin3.png";
// import UseFormResetMdp from "hooks/UseFormResetMdp";
// import { DASHBOARD_RELATION_PATH } from "routes/navigation/navigationPaths";
import hotels from "image/azalai.png";
import { FaLock } from "react-icons/fa";
import { ImageLogin, LogoForm,
    ParaLogoForm, ConnexionBody } from "components/ConnexionForm/LoginPageStyle"
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import COLORS from "utils/Variables/color";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
// import { useResetPasswordMutation } from "actions/authentification/mutation"
import UseFormResetMdp from "hooks/UseFormResetMdp";

const ConnexionFormResetMdp = () => {
    // const navigate = useNavigate();
    const images = [imageLogin1, imageLogin2, imageLogin3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
      return () => clearInterval(interval);
    }, []);
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const { handleResetPWD, isLoading } = UseFormResetMdp(setError);
    const handleSoumet = handleSubmit((data) => {
        console.log({ data });
        handleResetPWD(data);
        // setTimeout(() => {
        //     navigate("/");
        //   }, 1500);
      })
    const [showPassword, setShowPassword] = useState(false);
    const [showPassworde, setShowPassworde] = useState(false);

    return (
        <ConnexionBody className="">
            <ToastContainer className={"mt-5"}/>
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 d-md-block d-none m-0 py-0">
                <ImageLogin imageUrl={images[currentImageIndex]} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column gap-2 align-items-center justify-content-center">
                    <NavLink to="/" className="d-inline-flex align-items-center mt-1 gap-2 text-dark">
                        <LogoForm src={hotels} alt="Hotels" className="" />
                        <ParaLogoForm>Azalai hotel</ParaLogoForm>
                    </NavLink>
                    <form className="" onSubmit={handleSoumet}>
                        <div className="d-flex flex-column gap-2" style={{ padding: "10px" }}>
                            <div style={{ fontSize: "26px", fontWeight: "600" }}>
                            Changement de votre mot de passe
                            </div>
                            {/* <input
                        type="email"
                        className="form-control py-3 bg-transparent border border-0"
                        placeholder="Mettez le code que vous avez reçu au niveau de votre compte mail"
                        // value={newPassword}
                        // onChange={(e) => setNewPassword(e.target.value)}
                        {...register("email", {
                            required: "Mettez l'email",
                          })}
                        /> */}
                            <div>
                           <label className="fw-bold pb-1">Code</label>
                            <div className="input-group mb-3 border border-black rounded">
                        <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                        <FaLock />
                        </span>
                        <input
                        type="number"
                        className="form-control py-3 bg-transparent border border-0"
                        placeholder="Tapez le code reçu par e-mail"
                        // value={newPassword}
                        // onChange={(e) => setNewPassword(e.target.value)}
                        {...register("code", {
                            required: "Mettez le code",
                          })}
                        />
                            </div>
                            {errors.code && <div className="alert alert-danger gfa-alert-danger" role="alert">
                                {errors.code.message}
                            </div>}
                           </div>
                           <div>
                           <label className="fw-bold pb-1">Nouveau mot de passe</label>
                            <div className="input-group mb-3 border border-black rounded">
                        <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                        <FaLock />
                        </span>
                        <input
                        type={showPassword ? "text" : "password"}
                        className="form-control py-3 bg-transparent border border-0"
                        placeholder="................."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        // value={newPassword}
                        // onChange={(e) => setNewPassword(e.target.value)}
                        {...register("new_password", {
                            required: "Mettez votre nouveau mot de passe",
                          })}
                        />
                        <span className="d-flex align-items-center p-3"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: "pointer" }}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                            </div>
                            {errors.new_password && <div className="alert alert-danger gfa-alert-danger" role="alert">
                                {errors.new_password.message}
                            </div>}
                           </div>
                        <label htmlFor="mail" className="fw-bold pb-1">Confirmer mot de passe</label>
                        <div className="input-group mb-3 border border-black rounded">
                            <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                            <FaLock />
                            </span>
                            <input
                            type={showPassworde ? "text" : "password"}
                            className="form-control py-3 bg-transparent border border-0"
                            placeholder="................."
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            // value={confirmPassword}
                            // onChange={(e) => setConfirmPassword(e.target.value)}
                            {...register("new_password_confirm", {
                                required: " Veuillez confirmer le mot de passe",
                              })}
                            />
                            <span className="d-flex align-items-center p-3"
                                onClick={() => setShowPassworde(!showPassworde)}
                                style={{ cursor: "pointer" }}>
                                {showPassworde ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {errors.new_password_confirm && <div className="alert alert-danger gfa-alert-danger" role="alert">
                                {errors.new_password_confirm.message}
                            </div>}
                            <div className="text-center">
                            <button
                  type="submit"
                  className="w-100"
                  style={{
                    backgroundColor: `${COLORS.activeColor}`,
                    borderRadius: "5.333px",
                    padding: "12px 0px",
                    border: "none",
                    fontSize: "21.33px",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  <span>{isLoading ? "Connexion..." : "Se connecter"}</span>
                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ConnexionBody>
    );
}

export default ConnexionFormResetMdp;
