import React from "react";
// import { DivBreadCrumb } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { Outlet } from "react-router-dom";

// import { useSelector } from "react-redux";
// import BreadCrumbs from "components/RelationClientDetail/BreadCrumb";

const RelationClient = () => {
  // const navbarWidth = useSelector((state) => state.sidebarWidth.navbarWidth);
  // const { id } = useParams();

  return (
    <div className="d-flex flex-column ">
      {/* <div style={{ marginBottom: "10px" }}>
        <DivBreadCrumb width={navbarWidth} style={{ marginTop: "20px" }}>
          <BreadCrumbs id={id} />
        </DivBreadCrumb>
      </div> */}
      <Outlet />
    </div>
  );
};

export default RelationClient;
