import React from "react"
import ConversationCard from "components/RelationClientDetail/MenuClientDetails/ConversationCard"
// import { Heading5 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss"
import ConversationAtraiterCard from "components/RelationClientDetail/MenuClientDetails/ConversationAtraiterCard"
import styled from "styled-components"
import BreadCrumbs from "components/Breadcrumb/Breadcrumb"
import { Link } from "react-router-dom"
import "../../../../components/RelationClientDetail/Traiter/Scroll.css"

const Heading1 = styled.h1`
font-size: 26px;
font-family: Roboto;
/* padding-top: 10px; */
font-weight: 400;
@media (max-width: 768px) {
  font-size: 24px;
}
`;
const Barre = styled.div`
border: 2.44px solid #655573;
width: 218px;
`
const Barre2 = styled(Barre)`
width: 300px;
`

export default function DemandeClient() {
  return (
    <div style={styledRelationClientDetail.relationClient} className="px-5 py-4 ">
      <BreadCrumbs />
        <ContainerDemandeClient className="container-fluid mt-3 shadow pt-3">
            <div className="d-flex flex-row align-items-center px-5" style={{ backgroundColor: "#FFFFFF", height: "40px", width: "90%" }} >
            <Link className="atraiter" >À traiter</Link> <span className="barre">/</span> <Link className="dtraiter">Déjà traités</Link>
            <span className="barre">/</span> <span className="dtraiter">Demande du client</span>
                {/* <Heading5>A traiter</Heading5> */}
            </div>
            <div className="row px-5">
                <div className="col-lg-6 col-md-12" style={{ borderRight: "1px solid #E0E0E0" }}>
                <Heading1 style={{ color: "#6C5B7B" }}>Demande du client</Heading1>
                  <Barre></Barre>
                    <div className="row d-flex align-items-center mt-5">
                        <ConversationAtraiterCard sizeText={{ FontSize: "18px" }} colorIcon={"#E0E0E0"}
                            text="Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."
                            date="10-03-2024 / 08:45" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <Heading1 style={{ color: "#6C5B7B" }}>Traitement de la demande</Heading1>
                    <Barre2></Barre2>
                    <ConversationCard
                    nameHotel="<...>Azalai Hôtel Dakar"
                    text={"Pas de problème ! Nous avons réservé une table pour vous au restaurant."}
                    textBas="Nous vous attendrons à l'heure convenue."
                    date={"10-03-2024 / 08:45"}
                    id={"messageId"}
                    />
                </div>
            </div>
        </ContainerDemandeClient>
    </div>
  )
}
