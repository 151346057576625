import React, { useState, useEffect } from "react"
import Navbar from "layouts/navbar/Navbar"
import Footer from "layouts/Footer/Footer"
import { Outlet } from "react-router-dom";
import logoSrc from "image/LogoAzalai.png"
import { useTranslation } from "react-i18next";

function SplitShellForm() {
  const [lang, setLang] = useState("Français");
  const { i18n } = useTranslation();

  // Met à jour la langue dans i18next lorsque `lang` change
  useEffect(() => {
    const currentLang = lang === "Français" ? "fr" : "en";
    i18n.changeLanguage(currentLang);
  }, [lang, i18n]);
  return (
    <div>
         <Navbar
     logoSrc={logoSrc}
     setLang={setLang}
     lang={lang}
     link={"accueilForm"}
    />
    <div style={{ paddingBottom: "4%" }}></div>
         <Outlet />
    <Footer/>
    </div>
  )
}

export default SplitShellForm