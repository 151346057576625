import React from "react";
import { Dialog } from "primereact/dialog";
import COLORS from "utils/Variables/color";
import { useSelector } from "react-redux";
import { useGetTextesPrepareQuery } from "actions/textPrepare/query";
// import { useGetTextesPrepareQuery } from "actions/apiSliceRTK";

function ModalTextPrep({ visible, setVisible, closeModal }) {
  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const { data: emailsData } = useGetTextesPrepareQuery(userId);
  const emails = emailsData?.data || [];
  const emailsPreStay = emails.filter((email) => {
    const prestay = email.statut === "Pres_stay";
    return prestay;
  });
  const emailsPostStay = emails.filter((email) => {
    const prestay = email.statut === "Post_stay";
    return prestay;
  });
  const emailsInStay = emails.filter((email) => {
    const prestay = email.statut === "In_stay";
    return prestay;
  });
  return (
    <div className="card flex justify-content-center">
      <Dialog
        visible={visible}
        style={{ width: "30vw", minHeight: "auto", maxHeight: "100vh" }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
        closable={false}
      >
        <div className="mb-2">
          <h5
            style={{
              backgroundColor: `${COLORS.secondaryBackground}`,
              color: "#fff",
            }}
            className="p-2"
          >
            Pre Stay
          </h5>
          <div className="p-2">
            {emailsPreStay.map((emailPreStay, index) => (
              <p onClick={() => closeModal(emailPreStay.id)} key={index}>
                {emailPreStay.nom_email}
              </p>
            ))}
          </div>
        </div>
        <div className="mb-2">
          <h5
            style={{
              backgroundColor: `${COLORS.secondaryBackground}`,
              color: "#fff",
            }}
            className="p-2"
          >
            In Stay
          </h5>
          <div className="p-2">
            {emailsInStay.map((emailInStay, index) => (
              <p onClick={() => closeModal(emailInStay.id)} key={index}>
                {emailInStay.nom_email}
              </p>
            ))}
          </div>
        </div>
        <div className="mb-2">
          <h5
            style={{
              backgroundColor: `${COLORS.secondaryBackground}`,
              color: "#fff",
            }}
            className="p-2"
          >
            Post Stay
          </h5>
          <div className="p-2">
            {emailsPostStay.map((emailPostStay, index) => (
              <p onClick={() => closeModal(emailPostStay.id)} key={index}>
                {emailPostStay.nom_email}
              </p>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ModalTextPrep;
