import React from "react";
import { SidebarItems, SidebarItemsFiltre, Titles } from "pages/Admin/StyledAdmin";


function SidebarItem({ icon, titles, positionIcon, path, id, nombre, handleFilterDelete }) {
  return (
    <SidebarItems className="d-flex p-3 mb-2 mt-2" to={path} id={id} onClick={handleFilterDelete}>
        <div className="col-md-9 d-flex align-items-center gap-3">
            <span>{icon}</span>
            <Titles >{titles}</Titles>
        </div>
        <div className="col-md-3 d-flex justify-content-end align-items-center">
            <span className="mt-1 fw-bold">{nombre}</span>
            <span>{positionIcon}</span>
        </div>
    </SidebarItems>
  )
}

export default SidebarItem

export function SidebarItemFiltre({ icon, titles, positionIcon, path, id, nombre, handleFilterChange, value }) {
  return (
    <SidebarItemsFiltre className="d-flex p-3 mb-2 mt-2" to={path} id={id} onClick={() => handleFilterChange(value)}>
        <div className="col-md-9 d-flex align-items-center gap-3">
            <span>{icon}</span>
            <Titles>{titles}</Titles>
        </div>
        <div className="col-md-3 d-flex justify-content-end align-items-center">
            <span className="mt-1 fw-bold">{nombre}</span>
            <span>{positionIcon}</span>
        </div>
    </SidebarItemsFiltre>
  )
}