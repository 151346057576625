import React from "react";
import NavbarComponent from "components/NavbarComponent/NavbarComponent";
// import { NavbarContainer } from "components/HeaderListe/Navbarcss";
import styled from "styled-components";

 const NavbarContainer = styled.div`
  font-size: 16px;
  padding: 0 1%;
`;
const NavbarContainerParent = styled.div`
background-color: ${(props) => props.bgColor || "#FFC857"};
`;

const NavbarLayout = ({ bgColor }) => {
  return (
    <NavbarContainerParent className="border">
    <NavbarContainer bgColor={bgColor} className="container-fluid">
      <NavbarComponent />
    </NavbarContainer>
    </NavbarContainerParent>
  );
};

export default NavbarLayout;
