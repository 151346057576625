import React from "react";
import { Input, Label } from "./NouveauVoyageurCss";
import { useFormContext } from "react-hook-form";

export default function InputTexte({ label, name }) {
    const { register } = useFormContext();
    return (
            <>
                <Label className="mb-1">{label}<span style={{ color: "red" }}>*</span></Label>
                <Input {...register(name)} />
            </>
    )
}