import React from "react";
import styled from "styled-components";
import Titre from "components/FormulairePreStay/Titre";
import Text from "components/FormulairePreStay/Text";
import { GiUbisoftSun } from "react-icons/gi";
import wifi from "image/wifi.png"; // Importer les images
import presse from "image/presse.png";
import map from "image/map.png";
import juice from "image/juice.png";
import heart from "image/heart.png";
import Elipse1 from "image/Ellipse1.png";
import BoutonNeomorphisme from "components/FormulairePreStay/BoutonNeomorphisme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px; /* Réduction de l"espace entre les éléments */
  margin: 0 auto;
  padding: 20px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
`;
const Items = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
const Icon = styled.img`
  width: 10%;
`;
const LargeIcon = styled.img`
  width: 20%;
  border-radius: 50%;
`;

const ServiceGratuit = () => {
  return (
    <div className="d-flex justify-content-center">
      <Container>
        <div>
          <Titre
            text="Services Gratuits"
            style={{
              color: "#D26129",
              fontFamily: "Roboto",
              fontSize: "22.28px",
              fontWeight: "600",
            }}
          />
        </div>
        <Section className="">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="flex flex-column gap-3">
                <Item>
                  <Icon src={wifi} alt="Wi-Fi" />
                  <Text content="wifi" />
                </Item>
                <Item>
                  <Icon src={presse} alt="Presse" />
                  <Text content="Presse" />
                </Item>
                <Item>
                  <Icon src={map} alt="Plan" />
                  <Text content="Plan de ville" />
                </Item>
              </div>
            </div>
            <div className="col-md-6">
              <div className="flex flex-column gap-3">
                <Item>
                  <Icon src={juice} alt="" />
                  <Text content="Rafraichissement à l'arrivée" />
                </Item>
                <Item>
                  <Icon src={heart} alt="" />
                  <Text content="Produit d'accueil" />
                </Item>
                <Item>
                  <Icon as={GiUbisoftSun} size="24px" color="#D26129" alt="" />
                  <Text content="Coworking café" />
                </Item>
              </div>
            </div>
          </div>
        </Section>
        <div className="row">
          <div className="col-md-6 px-0">
            <Items>
              <LargeIcon src={Elipse1} alt="Wi-Fi" />
              <div>
                <Text
                  content={
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Lit bébé
                    </span>
                  }
                />
                <br />
                <Text
                  content={
                    <span style={{ fontSize: "13px" }}>
                      Disponible à partir de la <br /> catégorie "supérieure"
                    </span>
                  }
                />
              </div>
              <span>
                <BoutonNeomorphisme type="button" />
              </span>
            </Items>
          </div>
          <div className="col-md-6 px-0">
            <Items>
              <LargeIcon src={Elipse1} alt="" />
              <div>
                <Text
                  content={
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Lit Separe
                    </span>
                  }
                />
                <br />
                <Text
                  content={
                    <span style={{ fontSize: "13px" }}>
                      Disponible à partir de la <br /> catégorie "Standart"
                    </span>
                  }
                />
              </div>
              <BoutonNeomorphisme type="button" />
            </Items>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServiceGratuit;
