import "./InputCalendrier.css";
import React from "react";
import { Calendar } from "primereact/calendar";
import { Label } from "./NouveauVoyageurCss";
import { useFormContext } from "react-hook-form";
export default function InputCalendrier({ label, name }) {
  const { register, setValue } = useFormContext();
  const handleChange = (e) => {
    setValue(name, e.value);
  };
  return (
    <>
      <Label className="mb-1">
        {label}
        <span style={{ color: "red" }}>*</span>
      </Label>
      <Calendar
        // type="date"
        id={name}
        value={register(name).value}
        onChange={handleChange}
        showIcon
        showButtonBar
        className={`${register(name)?.message ? "p-invalid" : ""}`}
        style={{ height: "40px" }}
      />
    </>
  );
}
