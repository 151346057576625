import React from "react";
import DrapeauSenegal from "image/DrapeauSenegal.jpg";
import InfoCardDetails from "components/InfoCarDetails/InfoCardDetails";
// import styled from "styled-components";

// const GrpTitre = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 10px;
// `;

// const TitreCard = styled.h5`
//   font-size: 14px;
//   margin: 5px;
// `;

function ATraiterCard({ client, formatDate, func }) {
  const name = `${client.prenom} ${client.nom}`;

  return (
    <div>
      {/* <GrpTitre>
        <p className="text-primary fs-6">À traiter</p>
        <TitreCard>/</TitreCard>
      </GrpTitre> */}

      <InfoCardDetails
        className="mt-0"
        func={func}
        padding="2px"
        name={name}
        Pays="Senegal"
        countryImage={DrapeauSenegal}
        preStayDate={formatDate(client.created_at)}
        style={{ overflowY: "scroll", height: "400px" }}
      />
    </div>
  );
}

export default ATraiterCard;
