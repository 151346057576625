import styled from "styled-components";
import BgGroup from "image/BgGroup.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BgGroup});
  background-size: cover;
  background-position: center;
`;
export const Title = styled.h3`
  color: #ffffff;
  font-size: 26px;
  font-family: roboto;
  text-decoration: underline;
  text-align: center;
  padding-top: 90px;
  margin-bottom: 50px;
`;
export const Cadre = styled.div`
  background-color: white;
  color: black;
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 14px;
  height: calc(73vh - 120px);
`;
export const CadreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // margin: 10px;
  margin-bottom: 20px;

  @media (max-width: 882px) {
     display: block;
  }
  @media (max-width: 436px) {
    display: block;
  }
`;
export const Navig = styled.div`
  display: flex;
  gap: 10px;
`;
export const GrpBtn = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 767px) {
     flex-direction: column;
  }
`;
export const CadreHeader2 = styled.div`
  display: flex;
  margin-bottom: 20px;
   @media (max-width: 767px) {
     flex-direction: column;
  }
`;
export const InputDate = styled.div`
  width: 180px;
  height: 25px;
  margin-left: 20px;
  outline: none;
  border-radius: 45px;
  font-size: 12px;
  border: 1px solid #ffc857 important!;

  ::placeholder {
    color: #db1a1a;
  }
`;

export const CadreTab = styled.div`
  margin: auto; 
  // box-shadow: 1px 1px 3px gray;
  margin-top: 0px;

`;
export const Icone = styled.p`
  font-size: 17px;
  font-weight: bold;
  color: #ffc857;
  margin: 0px;
  margin-top: -3px;
`;
export const TextBut = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
  // margin-left: 2px;
  padding: 5px 10px;
`;

export const Select = styled.select`
  width: 150px;
  height: 25px;
  outline: none;
  border-radius: 20px;
  border: 1px solid gray;
  font-size: 12px;
`;
export const SelectGVoyag = styled.select`
  outline: none;
  border-radius: 50px;
  border: 1px solid gray;
  font-size: 14px;
  color: #000000;
  font-family: Montserrat;
  font-weight: 400;
  padding: 0 10px;
`;
export const HeaderTexte = styled.span`
font-family: "Roboto";
 font-weight: "400";
 font-size: "18px"; 
 line-height: "20px" ;
`;

export const ButtonAll = styled.button`
  height: 30px;
  outline: none;
  border-radius: 20px;
  border: 1px solid gray;
  font-size: 14px;
  background: none;
  &:hover {
    background-color: #ffc857;
    border: none;
    font-weight: 600;
  }
`;
