import React from "react"
import SidebarItem, { SidebarItemFiltre } from "./SidebarItem";
import { affichAll, enquete, messages } from "../UtilsSidebar";
import { TEXTESPREPARES, TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
// import SIZE from "utils/Variables/size";

const Entete = styled.span`
 font-size: 16px;
 font-weight: 500;
 color: #000000;
font-family: Poppins;
`

function SidebarTextPrepares({ handleFilterChange, handleFilterDelete }) {
  const PATH_EMAIL = `/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`;
  const location = useLocation();
  return (
    <div className="px-3">
      {/* <div className="row px-2 pt-2">
      </div> */}
      <div className="row gap-1 px-2">
        <div className="pb-2">
          {affichAll.map((item, index) => (
            <SidebarItem {...item} key={index} handleFilterDelete={handleFilterDelete} />
          ))}
        </div>
        <div className="mb-2">
          <Entete>Messages</Entete>
          {location.pathname === `${PATH_EMAIL}` ? messages.filter((items) => items.id === "email").map((item, index) => (
            <SidebarItem {...item} key={index} />
          )): messages.map((item, index) => (
            <SidebarItem handleFilterDelete={handleFilterDelete} {...item} key={index} />
          ))}
        </div>
        <div>
          <Entete>Périodes du séjour</Entete>
          {enquete.map((item, index) => (
            <SidebarItemFiltre {...item} key={index} handleFilterChange={handleFilterChange} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarTextPrepares