import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OptionVenez, OptionReserve, OptionConnu } from "components/FormulairePreStay/data";
import Text from "components/FormulairePreStay/Text";
import Titre from "components/FormulairePreStay/Titre";
import BoutonNeomorphisme from "components/FormulairePreStay/BoutonNeomorphisme";
import clockSrc from "image/clock.png";
import calendarSrc from "image/calendar.png";
import Button from "components/FormulairePreStay/Bouton";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import {
    Container, IconContainer, Select, CustomInput,
    NeumorphicButtonContainer, ResponseText, Img
} from "components/FormulairePreStay/StyledFormPreStay"

const Propos = ({ onClick }) => {
    const [isButtonActive, setIsButtonActive] = useState(false);
    const toggleButton = () => {
        setIsButtonActive((prev) => !prev);
    };
    // const [selectedTime, setSelectedTime] = useState(null);
    const { t } = useTranslation();
    const { register, control } = useFormContext();

    return (
        <>
            <div className="flex align-items-center flex-column">
                <Titre
                    text={t("À propos de vous")}
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "28px",
                        fontWeight: 600,
                    }}
                />
                <Container className="col-lg-6 col-sm-12 col-md-12 col-xl-6">
                    <IconContainer>
                        <Select {...register("type_client")}>
                            {
                                OptionVenez.map((item) => (
                                    <option key={item.id} value={item.value}>{item.name}</option>
                                ))
                            }
                        </Select>
                    </IconContainer>
                    <IconContainer>
                        <Select {...register("motif_voyage")}>
                            {
                                OptionReserve.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Select>
                    </IconContainer>
                    <IconContainer>
                        <Select {...register("biaiConnaissance")}>
                            {
                                OptionConnu.map((item) => (
                                    <option key={item.id} value={item.value}>{item.name}</option>
                                ))
                            }
                        </Select>
                    </IconContainer>
                    <NeumorphicButtonContainer>
                        <div>
                            <Text color="#555555" size={"16px"} content="Avez-vous l'habitude de venir à Dakar ?" />
                        </div>
                        <BoutonNeomorphisme onClick={toggleButton} active={isButtonActive} type="button" />
                        <ResponseText>{isButtonActive ? "Oui" : "Non"}</ResponseText> {/* Change le texte en fonction de l'état */}
                    </NeumorphicButtonContainer>
                    <IconContainer>
                        <div className="my-unique-datepicker">
                            <Controller
                                control={control}
                                name="date_naissance"
                                render={({ field }) => (
                                    <DatePicker
                                        selected={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        placeholderText="Indiquez votre date de naissance"
                                        customInput={<CustomInput />}
                                    />
                                )}
                            /></div>
                        <Img src={calendarSrc} />
                    </IconContainer>
                    <IconContainer>
                        <div className="my-unique-datepicker">
                            <Controller
                                control={control}
                                name="heure_d_arrivee"
                                render={({ field }) => (
                                    <DatePicker
                                        selected={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        placeholderText="Indiquez votre horaire d'arrivée estimée"
                                        customInput={<CustomInput />}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                    />
                                )}
                            />
                            {/*  <DatePicker
            selected={selectedTime}
            onChange={(date) => setSelectedTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            placeholderText="Indiquez votre horaire d'arrivée estimée"
            customInput={<CustomInput/>} {...register("heure_d_arrivee")}
        /> */}</div>
                        <Img src={clockSrc} />
                    </IconContainer>
                    <Button onClick={onClick} name="Suivant" />
                </Container>
            </div>
        </>
    );
};

export default Propos;
