import { createSlice } from "@reduxjs/toolkit";

export const splitShellSlice = createSlice({
  name: "sidebarWidth",
  initialState: {
    width: "100px",
    navbarPanerWidth: "calc(100% - 100px)",
    navbarWidth: "calc(100% - 100px)",
    outletWidth: "calc(100% - 100px)",
    isReduire: false,
    isHover: false,
    isAdmin: false,
    letAdmin: "100px",
    activeTab: 0
  },
  reducers: {
    setIsReduire: (state, action) => {
      state.isReduire = action.payload;
    },
    setIsHover: (state, action) => {
      state.isHover = action.payload;
    },
    setNavbarPanerWidth: (state, action) => {
      state.navbarPanerWidth = action.payload;
    },
    setSidebarWidth: (state, action) => {
      state.width = action.payload;
    },
    setNavbarWidth: (state, action) => {
      state.navbarWidth = action.payload;
    },
    setOutletWidth: (state, action) => {
      state.outletWidth = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setLetAdmin: (state, action) => {
      state.letAdmin = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  },
});

export const { setActiveTab, setLetAdmin, setIsAdmin, setOutletWidth, setIsHover, setNavbarPanerWidth, setNavbarWidth, setSidebarWidth, setIsReduire } = splitShellSlice.actions;
