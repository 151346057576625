import { ApiMananger } from "actions/rtkquery";
import {
  formulaireByIdEndepoint,
  serviceBasesEndepoint,
  serviceCommandesEndepoint,
} from "routes/api/endpoints";

export const serviceMutation = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addDataForm: builder.mutation({
      query: (formData) => ({
        url: serviceCommandesEndepoint.ServicesCommandees(),
        method: "POST",
        body: formData,
      }),
    }),
    updateService: builder.mutation({
      query: ({ serviceId, serviceData }) => ({
        url: serviceCommandesEndepoint.ServicesCommandeesById(serviceId),
        method: "PUT",
        body: serviceData,
      }),
    }),
    addDataFormSC: builder.mutation({
      query: (formData) => ({
        url: serviceBasesEndepoint.getServiceBases(),
        method: "POST",
        body: formData,
      }),
    }),
    addDataFormAbout: builder.mutation({
      query: (formData) => ({
        url: serviceBasesEndepoint.getServiceBases(),
        method: "POST",
        body: formData,
      }),
    }),
    getServices: builder.mutation({
      query: (body) => ({
        url: formulaireByIdEndepoint.getServices(),
        method: "POST", // Correct the typo here
        body: body,
      }),
    }),
  }),
});

export const {
  useGetServicesMutation,
  useAddDataFormMutation,
  useUpdateServiceMutation,
  useAddDataFormSCMutation,
} = serviceMutation;
