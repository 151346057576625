import React from "react";
import { ContCard, ContIcone, Heading4, Hithligne, IconeBas } from "../InfoClient/InfoClientCSS";
import badge from "image/carbon_badge.png"
import { BiSolidEditAlt } from "react-icons/bi";


function Badges() {
    return (
        <ContCard className="border rounded d-flex flex-column px-3 py-5 bg-white mb-2">
            <div className="d-flex justify-content-between">
                <Heading4>Badges</Heading4>
                <ContIcone>  <BiSolidEditAlt style={{ color: "blue", fontSize: "18px" }} /></ContIcone>
            </div>
            <Hithligne></Hithligne>
            <IconeBas src={badge} alt="" />
        </ContCard>
    )
}
export default Badges