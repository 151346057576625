import styled from "styled-components";

export const Searchinput = styled.div`
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: end;
  gap: 5px;
  justify-content: center;
  margin: 0px;
  padding: 4px 16px;
`;
export const SearchIcone = styled.span`
  color: black;
  font-size: 15px;
`;
export const Input = styled.input`
  border: none;

  ::placeholder {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.87px;
  }
`;
export const ContInput = styled(Searchinput)`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
`;
export const InputS = styled(Input)`
  ::placeholder {
    color: black;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
  }
`;
