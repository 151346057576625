// import ConnexionForm from "components/ConnexionForm/ConnexionForm"
// import ConnexionFormResetMdp from "components/ConnexionFormResetMdp/ConnexionFormResetMdp"
// import DetailsReservation from "pages/DetailsReservation/DetailsReservation"
import React from "react"

const Marketing = () => {
  return (
    <div>
      <p>marketing</p>
      {/* <DetailsReservation /> */}
      {/* <ConnexionFormResetMdp /> */}
    </div>
  )
}

export default Marketing
