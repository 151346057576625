import React from "react";
import styled from "styled-components";

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width};
`;

// const StyledSelect = styled.select`
//   appearance: none; /* Enlever la flèche par défaut */
//   width: 100%;
//   padding: ${(props) => props.padding};
//   font-size: ${(props) => props.fontSize};
//   font-weight: ${(props) => props.fontWeight};
//   color: ${(props) => props.color};
//   background-color: ${(props) => props.backgroundColor};
//   border: ${(props) => props.border};
//   border-radius: ${(props) => props.borderRadius};
//   height: ${(props) => props.height};
//   margin: ${(props) => props.margin};
//   text-align: center; /* Centrer le texte */
//   cursor: pointer;
// `;
const Select = styled.select`
  background: white;
  border: 1px solid #ffc857;
  color: #000000;
  font-weight: 400;
  border-radius: 40px;
  font-size: 14px;
  font-family: "Roboto";
  padding: 4px;
`;
const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  // pointer-events: none; /* Empêcher l'icône d'interférer avec les clics */
`;

const CustomSelect = ({
  className,
  width,
  options = [],
  onChange,
  classSelect,
  padding,
  fontFamily,
  fontSize,
  fontWeight,
}) => {
  return (
    <SelectContainer
      width={width}
      padding={padding}
      className={className}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}>
      <Select className={classSelect} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Icon></Icon> {/* Icône en forme de V */}
    </SelectContainer>
  );
};

export default CustomSelect;
