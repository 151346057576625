// hooks/useLogin.js

import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setAccessToken, setRefreshToken, setUser, setFirstName, setLastName, addNewVoyageur } from "redux/features/userSliceWithTokenValidation";
// import { useLoginUserMutation } from "actions/apiSliceRTK";
import { useNavigate } from "react-router-dom";
import { RELATION_CLIENT_PATH } from "routes/navigation/navigationPaths";
import { useLoginUserMutation } from "actions/authentification/mutation";

const useLogin = () => {
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (data, reset) => {
    try {
      const { data: userData, error } = await loginUser(data);
      if (error) {
        if (error.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Vous n'êtes pas autorisé à vous connecter.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de la connexion.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        reset();
      } else {
        if (userData.status === "HOTELIER") {
          const userId = userData.id;
          dispatch(setUser(userId));
          dispatch(setAccessToken(userData.access));
          dispatch(setRefreshToken(userData.refresh));
          dispatch(setFirstName(userData.first_name));
          dispatch(setLastName(userData.last_name));
          dispatch(addNewVoyageur(userData.voyageur));
          reset();
          navigate(`/${RELATION_CLIENT_PATH}`);
        }
      }
    } catch (err) {
      console.log("Error during login:", err);
    }
  };

  return { handleLogin, isLoading };
};

export default useLogin;
