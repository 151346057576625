import { createSlice } from "@reduxjs/toolkit";

const userSliceWithTokenValidation = createSlice({
    name: "auth",
    initialState: {
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        user_id: null,
        first_name: null,
        last_name: null,
        voyageur: null,
        reservation: [],
        email: null,
        isUpdate: null,
    },
    reducers: {
        setIsUpdate: (state, action) => {
          state.isUpdate = action.payload;
        },
        setAccessToken: (state, action) => {
            return {
                ...state,
                accessToken: action.payload,
                isAuthenticated: true
            }
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setCredentials: (state, action) => {
            return {
                ...state,
                refreshToken: action.payload.refresh_token,
                accessToken: action.payload.access_token,
                isAuthenticated: true
            }
        },
        setUser: (state, action) => {
            return {
                ...state,
                user_id: action.payload.user_id,
                email: action.payload.email,
            };
        },
        setFirstName: (state, action) => {
            return {
                ...state,
                first_name: action.payload,
            };
        },
        setLastName: (state, action) => {
            return {
                ...state,
                last_name: action.payload,
            };
        },
        setSkeep(state) {
            state.isSkeep = true;
        },
        logout: (state) => {
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                isAuthenticated: false,
                user_id: null,
                first_name: null,
                last_name: null,
                email: null,
            }
        },
        addNewVoyageur: (state, action) => {
            return {
                ...state,
                voyageur: action.payload,
            }
        },
        addNewReservation: (state, action) => {
            return {
                ...state,
                reservation: action.payload,
            }
        },
        removeReservation: (state, action) => {
                state.reservation = state.reservation.filter((reservation) => reservation.id!== action.payload);
        },
        addNewVoyageurReservation: (state, action) => {
            console.log(action);
            return {
                ...state,
                reservation: action.payload,
            }
        },
        addTextePrepare: (state, action) => {
            console.log(action);
            return {
                ...state,
                email: action.payload,
            }
        },
        removeTextPrepare: (state, action) => {
            state.email = state.email.filter((email) => email.id !== action.payload);
        },
    },
});

export const getUserStatus = (state) => state?.persist?.auth.isAuthenticated;
export const getSkeep = (state) => state?.persist?.dataPersisted?.user?.isSkeep;
export const selectToken = (state) => state?.persist?.auth.accessToken;
export const selectFirstName = (state) => state?.persist?.auth.first_name;
export const selectLastName = (state) => state?.persist?.auth.last_name;
export const selectEmail = (state) => state.persist?.auth?.email;

export const { setIsUpdate, removeTextPrepare,
     removeReservation, addNewVoyageurReservation, addNewReservation,
    addNewVoyageur, setCredentials, setAccessToken, addTextePrepare,
    setUser, setRefreshToken, logout, setSkeep, setFirstName, setLastName
} = userSliceWithTokenValidation.actions;

export default userSliceWithTokenValidation.reducer;