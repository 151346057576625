import React, { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DETAIL_CLIENT,
  RELATION_CLIENT_PATH,
  UPDATE_VOYAGEUR_PATH,
} from "routes/navigation/navigationPaths";
import { useNavigate } from "react-router-dom";
import { useDeleteReservationMutation } from "actions/reservation/mutation";
import Modal from "components/CustomComponent/Modal";

const CadreTableau = ({
  data,
  formatDate,
  refetch
}) => {
  const [deleteReservation] = useDeleteReservationMutation();
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState(null);
  const openModal = (reservationId) => {
    setSelectedReservationId(reservationId); // Stocke l'ID sélectionné
    setModalOpened(true); // Ouvre le modal
  }
  const closeModal = () => {
    setModalOpened(false);
  };
  const handleDelete = async () => {
    if (selectedReservationId) {
      try {
        const res = await deleteReservation(selectedReservationId).unwrap();
        console.log("Voyageur supprimé avec succès", res);
        toast.success("Voyageur supprimé avec succès");
        refetch();
        closeModal();
      } catch (error) {
        toast.error("Erreur lors de la suppression");
        refetch();
        closeModal();
        console.error("Erreur lors de la suppression:", error);
      }
    }
  };
  const navigate = useNavigate();
  const handleEdite = async (voyageur) => {
    navigate(`/${UPDATE_VOYAGEUR_PATH}/${voyageur.voyageur_details.id}`);
    console.log(voyageur);
  };
  const handleDetails = async (voyageur) => {
    navigate(`/${RELATION_CLIENT_PATH}/${DETAIL_CLIENT}/${voyageur.voyageur_details.id}`);
  };

  return (
    <div className="overflow-x-scroll">
    <Modal opened={modalOpened} onClosed={closeModal}
    title={"Confirmation"} confirme={handleDelete} cancel={closeModal}>
      <p>Es-tu sûr de vouloir supprimer ce voyageur ?</p>
      </Modal>
      <table
        className="mt-2 table table-hoverd"
        style={{
          fontSize: "14px",
          fontFamily: "Roboto",
          verticalAlign: "middle",
          marginLeft: "-20px",
        }}
      >
        <thead className="table-light" style={{ color: "#555555" }}>
          <tr style={{}} className=" align-middle">
            <th scope="col" className="py-2 px-4">
              {" "}
              <input type="checkbox" />
            </th>
            <th scope="col">Numéro Voyageur</th>
            <th scope="col">Prénom et nom</th>
            <th scope="col">Date de mise à jour du profil</th>
            <th scope="col">E-mail</th>
            <th scope="col">Date de naissance</th>
            <th scope="col">Type client</th>
            <th scope="col">Type de voyage</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data && data.map((item, index) =>
            item.archived ? null : (
              <tr key={index} className="align-middle">
                <td scope="row" style={{}} className="py-3 px-4">
                  <input type="checkbox" />
                </td>
                <td
                  scope="row"
                  className="fw-bold"
                  style={{ color: "#101828" }}
                >
                  {item?.voyageur_details?.id || "Indéfini"}
                </td>
                <td scope="row" className="" style={{ color: "#101828" }}>
                    {item?.voyageur_details?.prenom || "Indéfini"} {item?.voyageur_details?.nom || "Indéfini"}
                </td>
                <td scope="row" style={{ color: "#667085" }}>
                  {formatDate(item?.voyageur_details?.updated_at || "Indéfini")}
                </td>
                <td scope="row" style={{ color: "#667085" }}>
                  {item?.voyageur_details?.email || "Indéfini"}
                </td>
                <td scope="row" style={{ color: "#667085" }}>
                  {item?.voyageur_details?.date_naissance || "Indéfini"}
                </td>
                 <td scope="row" style={{ color: "#667085" }}>
                {item?.type_client || "Indéfini"}
                </td>
                <td scope="row" style={{ color: "#667085" }}>
                {item?.motif_voyage || "Indéfini"}
                </td>
                <td scope="row">
                  <span className="d-flex">
                    <IoEyeSharp
                      style={{ color: "gray", cursor: "pointer" }}
                      onClick={() => handleDetails(item)}
                    />
                    <RiDeleteBin6Line
                      className="ms-1 me-1"
                      style={{ color: "#D92D20", cursor: "pointer" }}
                      onClick={() => openModal(item.id)}
                    />
                    <FaEdit
                      style={{ color: "#0078D7", cursor: "pointer" }}
                      onClick={() => handleEdite(item)}
                    />
                  </span>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
        <ToastContainer className="mt-5" />
    </div>
  );
};

export default CadreTableau;
