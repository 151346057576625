import { authentificate } from "routes/api/endpoints";
import { logout } from "redux/features/userSliceWithTokenValidation";
import { ApiMananger } from "actions/rtkquery";

export const authentificationMutations = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: authentificate.onLogin(),
        method: "POST",
        body: { email, password },
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: authentificate.onLogout(),
        method: "POST",
      }),
      onSuccess: (_, { dispatch }) => {
        dispatch(logout());
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: authentificate.onForgotPassword(),
        method: "POST",
        body: { email },
      }),
      onSuccess: (response, { dispatch }) => {
        const message = response.data.message;
        dispatch(showSuccessMessage(message || "Instructions de réinitialisation envoyées avec succès."));
      },
    }),
    resetPassword: builder.mutation({
      query: ({ code, email, new_password_confirm, new_password }) => {
        console.log("Appel API avec les données suivantes:", { code, email, new_password_confirm, new_password });
        return {
          url: authentificate.onResetPassword(),
          method: "POST",
          body: { code, email, new_password_confirm, new_password },
        };
      },
    }),
  }),
});

export const {
  // Authentificate
  useLoginUserMutation,
  useLogoutUserMutation,
  useRefreshTokenMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authentificationMutations;
