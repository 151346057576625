import React from "react";
import styled from "styled-components";
import Details from "components/Footer/Details";
import Text from "components/FormulairePreStay/Text";
import Socio from "components/Footer/Socio";
import {
  faMapMarkerAlt,
  faPhone,
  faClock,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// Style pour le footer
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (min-width: 646px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 320px) and (max-width: 645px) {
    grid-template-columns: 1fr;
  }
`;
const FooterStyle = styled.footer`
  background: black;
  padding: 4% 9%;

   @media (max-width: 768px) {
    display: block;
  }

`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  font-size: 14px;
`;
const ContainCopyRight = styled.div`
 display: flex;
 justify-content: center;
 align-items: baseline;
 padding-top: 4%;
   @media (max-width: 768px) {
    display: block;
  }
`
const TextConfidentiel = styled.p`
font-family: "Roboto", sans-serif;
font-size: 12px;
`

export default function Footer() {
  return (
    <FooterStyle>
      <div>
        <GridContainer>
          <div>
          <Details
            color="grey"
            title="Azalai Hotel Dakar"
          />
          <Text colorLink="grey" weight={"600"} link="https://www.azalai.com/" contentLink="Azalai Group | "/>
          <Text colorLink="grey" weight="600" link="https://www.azalai.com/azalai-hotel-bamako" contentLink=" Azalai Hotel Bamako"/>
          <p style={{ margin: 0 }}>
         <Text colorLink="grey" weight={"600"} link="https://www.azalai.com/azalai-hotel-cotonou" contentLink=" Azalai Hotel Cotonou | "/>
          <Text colorLink="grey" weight={"600"} link="https://www.azalai.com/azalai-hotel-nouakchott" contentLink=" Azalai Hotel Nouakchott"/>
          </p>
          <Text colorLink="grey" weight={"600"} link="https://www.azalai.com/dunia-hotel-bissau" contentLink=" Dunia Hotel Bissau | "/>
         <Text colorLink="grey" weight={"600"} link="https://www.azalai.com/dunia-hotel-loumbila" contentLink=" Dunia Hotel Loumbila"/>
          <p><Text colorLink="grey" weight={"600"} link="https://www.azalai.com/grand-hotel-bamako" contentLink=" Grand Hotel Bamako "/></p>
          <div style={{ paddingTop: "12px" }}>
          <Text color="grey" contentLink="Corniche Ouest, Dakar, Senegal"/>
          <p style={{ margin: "0" }}> <Text color="grey" contentLink="Phone: "/><Text colorLink="grey" weight={"600"}
          link="tel:+221339232300" contentLink=" +221 33 923 23 00"/></p>
          <p style={{ margin: "0" }}><Text color="grey" contentLink="Email: "/><Text colorLink="grey" weight={"600"}
          link="mailto:reservationahd@azalaihotels.com" contentLink=" reservationahd@azalaihotels.com "/></p>
          </div>
          </div>
          <Details
            color="white"
            title="Contact"
            text={
              "Corniche Ouest, Dakar, Senegal / " +
              "+221 33 923 23 00 / " +
              "Du lundi au vendredi de 9h à 17h / " +
              "reservationahd@azalaihotels.com"
            }
            icons={[faMapMarkerAlt, faPhone, faClock, faEnvelope]}
            urls={[
              "https://www.google.com/maps/place/Corniche+Ouest,+Dakar,+Senegal",
              "tel:+221339232300",
              "#",
              "mailto:reservationahd@azalaihotels.com",
            ]}
            iconColors={["inherit", "inherit", "black", "inherit"]} // Spécifiez les couleurs pour chaque icône
          />
              <InputContainer>
                <TextConfidentiel>
                  <span style={{ color: "grey" }}>
                    Nous ne partagerons jamais vos coordonnées. <br />
                    Voir notre
                  </span>{" "}
                  <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">politique de confidentialité</a>
                </TextConfidentiel>
        <Socio />
              </InputContainer>
        </GridContainer>
        <ContainCopyRight>
        <Text color="grey" size="13px" weight={"600"} content="© 2024 Azalai Hotel Dakar | Made by TravelClick, An Amadeus Company."/>
        <p>
         <Text size="13px" colorLink="white" weight={"600"} link="#" contentLink="politique de confidentialité "/>
        <Text color="grey" size="13px" weight={"600"} content=" et "/>
        <Text size="13px" colorLink="white" weight={"600"} link="#" contentLink="Conditions d'utilisation."/>
        </p>
        </ContainCopyRight>
      </div>
    </FooterStyle>
  );
}
