import React, { useState } from "react";
import ConversationCard from "./ConversationCard";
import styled from "styled-components";
import { Editor } from "primereact/editor";
import "./EditorStyles.css";

const ConversationWrapper = styled.div`
    // position: absolue;
`;

const CustomEditorContainer = styled.div`
    width: 98%;
    margin: auto;
    // border: 1px solid #FFC857;
    // background-color: #FDE298 !important;
    align-items: center;
    // padding: 10px;
    position: relative;

`;

const CustomButtonContainer = styled.div`
    width: auto;
    position: absolute;
    right: 50px;
    bottom: 30px;
    display:flex;
    gap:5px;
`;

const SendButton = styled.button`
    padding: 12px 20px;
    background-color: #FFC857;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    z-index: 1;
    font-family: "Roboto";
    font-weight: 400
`;

const SendTextButton = styled.button`
    padding: 8px 20px;
    border: 1px solid #FFC857 ;
    border-radius: 25px;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
    font-family: "Roboto";
    font-weight: 400
`;

function Conversation({ client }) {
    const [messages, setMessages] = useState([
        {
            text: "Je voudrais réserver une table pour ce soir au restaurant de l'hôtel.",
            date: "10-03-2024 / 08:45",
            client: true
        },
    ]);
    const [text, setText] = useState("");

    const sendMessage = () => {
        if (text.trim()) {
            const newMessage = {
                text: text,
                date: new Date().toLocaleString(),
                client: true
            };
            setMessages([...messages, newMessage]);
            setText("");
        }
    };

    return (
        <ConversationWrapper>
            <p className="px-4" style={{
                color: "#0E233C", fontSize: "24px", fontFamily: "Roboto", fontWeight: 500,
                marginTop: "10px"
            }}>Conversation avec {client.prenom} {client.nom} </p>
            <div className="px-3">
                <div>
                    <CustomEditorContainer>
                        <Editor
                            value={text}
                            onTextChange={(e) => setText(e.htmlValue)}
                            placeholder="Message"
                            style={{ backgroundColor: "var(--primary-color)", height: "140px", border: "none" }}
                            className="custom-editor"
                            color="blue"
                        />
                        <CustomButtonContainer>
                            <SendTextButton onClick={sendMessage}>Textes Preparés</SendTextButton>
                            <SendButton onClick={sendMessage}>Envoyer</SendButton>
                        </CustomButtonContainer>
                    </CustomEditorContainer>
                </div>
                <div>
                    {messages.map((message, index) => (
                        <ConversationCard
                            key={index}
                            text={message.text}
                            date={message.date}
                            client={message.client}
                            nameHotel={message.nameHotel}
                            id={message.id}
                        />
                    ))}
                </div>
            </div>
        </ConversationWrapper>
    );
}

export default Conversation;
