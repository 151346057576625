import { useResetPasswordMutation } from "actions/authentification/mutation";
import useGetCurrentUser from "./useGetCurrentUser";
import { toast } from "react-toastify";

const UseFormResetMdp = (setError) => {
  const [ResetPWD, { isLoading }] = useResetPasswordMutation();
  const user = useGetCurrentUser();
  console.log({ user });

  const handleResetPWD = async (data) => {
    const email = localStorage.getItem("userEmail");
  console.log("Email récupéré du localStorage:", email);
  if (!email) {
    toast.error("Erreur : aucun email trouvé pour la réinitialisation du mot de passe.");
    return;
  }

    if (data.new_password !== data.new_password_confirm) {
      setError("new_password_confirm", { type: "manual", message: "Les mots de passe ne correspondent pas." });
      return;
    }

    try {
      const resetData = {
        code: data?.code,
        email, // Utiliser l'email stocké
        new_password: data.new_password,
        new_password_confirm: data.new_password_confirm
      };

      // Appeler la mutation pour réinitialiser le mot de passe
      await ResetPWD(resetData);

      toast.success("Mot de passe mis à jour avec succès !");
    } catch (err) {
      console.error("Échec de la mise à jour du mot de passe:", err);
      toast.error("Échec de la mise à jour du mot de passe.");
    }
  };
  return { handleResetPWD, isLoading };
};

export default UseFormResetMdp;