import React, { useState } from "react";
import { IoMdTime } from "react-icons/io";
import { GiCalendar } from "react-icons/gi";
import ConversationAtraiterCard from "./ConversationAtraiterCard";
import { Editor } from "primereact/editor";
import DrapeauSenegal from "image/DrapeauSenegal.jpg"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import DropdownCountry from "./DropdownCountry";
// import COLORS from "utils/Variables/color";
import ModalTextPrep from "./ModalTextPrep";
import styled from "styled-components";
// import { useGetTextesPrepareByIdQuery } from "actions/apiSliceRTK";
import "./scroller.css"
import { useGetTextesPrepareByIdQuery } from "actions/textPrepare/query";

export const Img = styled.img`
    width: 30px;
    height: 20px;
    border-radius: 3px;
`;
function ConversationAtraiter({ client }) {
    const [text, setText] = useState("");
    const [visible, setVisible] = useState(false);
    const name = `${client.prenom} ${client.nom}`
    //   const [emailId, setEmailId] = useState(null);
    let emailId = null
    const { data: emailData } = useGetTextesPrepareByIdQuery(emailId);
    const closeModal = (id) => {
        console.log({ id });
        emailId = id
        const email = emailData?.data || [];
        console.log({ emailId });
        setText(email.contenu)
        console.log({ email });
        setVisible(false);
    };
    return (
        <div className="px-4" style={{ fontSize: "12px" }}>
            <div className="card-header d-flex">
                <p className="text-primary pe-1">À traiter</p>
                <p style={{ fontWeight: "500" }}>/ Conversation avec {name}</p>
            </div>
            <div className="card-body custom-scrollbar" style={{ height: "800px" }}>
                <div className="head-body py-3">
                    <div className="row justify-content-between">
                        <div className="col-md-6 d-flex py-4">
                            <h6 style={{ fontSize: "14px" }}>Enquête Instay <span className="fw-bold"> {name}</span></h6>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <div>
                                <span className="fw-bold" style={{ fontSize: "14px" }}>Pays: </span>
                                <Img src={DrapeauSenegal} alt="img" />
                                <span className="ms-1" style={{ fontSize: "14px", color: "#667085" }}>Sénégal</span>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2 px-2">
                        <div className="col-md-2 d-flex align-items-center gap-1" style={styledRelationClientDetail.textConversation}>
                            <span><IoMdTime className="fs-4" /></span>
                            <p className="m-0">Il y'a un jour</p>
                        </div>
                        <div className="col-md-3 d-flex align-items-center gap-1 justify-content-center">
                            <span><GiCalendar className="fs-4" /></span>
                            <p className="m-0">Arrivé depuis 8h</p>
                        </div>
                        <div className="col-md-1">
                            <p className="m-0">Nuitée</p>
                            <span className="fw-bold">2</span>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center flex-column">
                            <p className="m-0">Arrivée</p>
                            <span className="fw-bold">14/03/2024</span>
                        </div>
                        <div className="col-md-2 justify-content-center flex-column">
                            <p className="m-0">Départ</p>
                            <span className="fw-bold">16/03/2024</span>
                        </div>
                        <div className="col-md-2 justify-content-center flex-column">
                            <p className="m-0">Chambre</p>
                            <span className="fw-bold">5A</span>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="col-md-5 py-2">
                        <span style={styledRelationClientDetail.borderTextConversation}>
                            <h6 className="">Informations obtenues</h6>
                        </span>
                    </div>
                    <div className="row pb-5 px-1">
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Type de client</p>
                            <span className="fw-bold">En famille</span>
                        </div>
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Type de voyage</p>
                            <span className="fw-bold">Loisir</span>
                        </div>
                        <div className="col-md-3" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Viens souvent à Dakar</p>
                            <span className="fw-bold">Non</span>
                        </div>
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Anniversaire</p>
                            <span className="fw-bold">04 Avril 1995</span>
                        </div>
                        <div className="col-md-3" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Heure d'Arrivée estimée</p>
                            <span className="fw-bold">09:45</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 border-end">
                            <div className="col-md-9">
                                <h6 className="fs-5 pb-2"><span style={styledRelationClientDetail.borderTextConversation} className="pb-2">Demande du client</span>(3)</h6>
                            </div>
                            <h6>Make a wish</h6>
                            <p>Nous avons oublié de vous proposer quelque chose ? dites le nous!</p>
                            <div>
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-10">
                                <h5 className="pb-2" style={styledRelationClientDetail.borderTextConversation}>Traitement des demandes</h5>
                            </div>
                            {/* <h5>À: {name}</h5> */}
                            <div className="row d-flex justify-content-between">
                                <div className="col-md-7">
                                    <p className="" style={{ color: "#667085", fontWeight: 400, fontSize: "18px", fontFamily: "Roboto" }}>Sujet:</p>
                                    <p className="" style={{
                                        borderBottom: "2px solid #655573",
                                        display: "flex",
                                        fontWeight: 500,
                                        fontSize: "18px",
                                        paddingBottom: "8px",
                                        marginTop: "-13px",
                                        fontFamily: "Roboto",
                                        color: "#101828"
                                    }}>Merci pour votre réponse </p>
                                </div>
                                <div className="col-md-5">
                                    <p className="" style={{ color: "#667085", fontWeight: 500, fontSize: "18px", fontFamily: "Roboto" }}>Langue:</p>
                                    <div className="" style={{
                                        borderBottom: "2px solid #655573",
                                        display: "flex",
                                        fontSize: "16px",
                                        paddingBottom: "8px",
                                        marginTop: "-13px",
                                    }}>
                                        <DropdownCountry />
                                    </div>
                                </div>
                            </div>


                            <div className="card border-0">
                                <div className="d-flex justify-content-end w-100" >
                                    <div className="border-top border-start border-end p-2 rounded-top"
                                        style={{
                                            backgroundColor: "#FFC857", cursor: "pointer", width: "250px", fontWeight: 400, fontSize: "18px", fontFamily: "Roboto", color: "#101828",
                                            padding: "15px"
                                        }}
                                        onClick={() => setVisible(true)}>
                                        <span style={{ backgroundColor: "#FFC857", fontWeight: 400, fontSize: "18px", fontFamily: "Roboto", color: "#101828" }}>Textes préparés {"{...}"}</span>
                                    </div>

                                </div>
                                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: "200px", border: "1px #667085" }} />
                            </div>
                        </div>
                        <ModalTextPrep visible={visible} setVisible={setVisible} closeModal={closeModal} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConversationAtraiter
