import React from "react"
// import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import HistoriqueElementTraite from "components/RelationClientDetail/Traiter/HistoriqueElementTraite";
import { useSelector } from "react-redux";
// import { useGetVoyageursQuery } from "actions/apiSliceRTK";
import styled from "styled-components";
import BreadCrumbET from "./BreadCrumbET";
import { useGetVoyageursQuery } from "actions/voyageur/query";


 const ContainerDemandeClient = styled.div`
  background: #fff;
  border-radius: 14px;
  min-height: 100vh;
  max-height: 100%;
  width: 93%;
`;
const ElementTraite = () => {
  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const { data: voyageursData } = useGetVoyageursQuery( userId )
  const voyageurs = voyageursData?.data || [];
  return (
    <div className="py-4" style={styledRelationClientDetail.relationClient}>
      <BreadCrumbET />
        <ContainerDemandeClient className="container-fluid py-4 px-5 mt-3">
          <HistoriqueElementTraite voyageurs={voyageurs} />
        </ContainerDemandeClient>
    </div>
  )
}

export default ElementTraite;
