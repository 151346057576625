import React, { useRef } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import csv from 'csv-parser';
// import fs from 'fs';
import styled from "styled-components";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;
const Entete = styled.div`
 display: flex;
 gap: 15px;
 margin-bottom: 20px;
`
const Icone = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 width: 40px;
 height: 40px;
 border-radius: 5px;
 border: 2px solid gray;
 font-size: 15px;
 padding: 10px;
 cursor: pointer;
`
const Title = styled.h2`
 display: flex;
 gap: 15px;
`
const Bottom = styled.div`
 border: 2px dashed gray;
 padding: 20px;
 border-radius: 7px;
`
const BottomText = styled.div`
 font-size: 25px;
 text-align: center;
 padding: 0;
`
const BottomIcone = styled.div`
 font-size: 40px;
 text-align: center;
 font-weight: bold;
`
const BottomPlus = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 font-weight: bold;
 font-size: 20px;
 margin: auto;
 padding: 17px;
 width: 50px;
 height: 50px;
 border-radius: 5px;
 background-color: #FFC857;
 cursor: pointer;
`

const ImportModal = ({ isOpen, onRequestClose, handleFileChange }) => {
  if (!isOpen) return null;
  const fileInputRef = useRef(null);
  const handleImportButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <ModalBackdrop onClick={onRequestClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Entete>
          <Icone onClick={onRequestClose}> <FaChevronLeft /> </Icone>
          <Title>Importer Excel / CSV</Title>
        </Entete>
        <Bottom>
          <BottomIcone> <IoCloudUploadOutline /> </BottomIcone>
          <BottomText>
            <p>Parcourez et choisissez les fichiers que vous voulez</p>
            <p>importer depuis votre ordinateur</p>
          </BottomText>
          <BottomPlus onClick={handleImportButtonClick}> <FaPlus /> </BottomPlus>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Bottom>
      </ModalContent>
      {/* <ToastContainer className="mt-5" /> */}
    </ModalBackdrop>
  );
};

export default ImportModal;
