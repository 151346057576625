import React, { useState, cloneElement } from "react";
import SidebarItem from "../../components/CustomComponent/SidebarItem";
import ResourceDataMap from "../../components/CustomComponent/ResourceDataMap";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/features/userSliceWithTokenValidation";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";
import { BiLogOut } from "react-icons/bi";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { links } from "routes/navigation/sidebarLinks";

const DivLogout = styled.div`
  background: #F0443A;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 25px;
  width: 35px;
  height: 35px;
`;
const ContainerSidebar = styled.div`
@media(min-width: 320px) and (max-width: 767px){
display: none !important;
}
`

const SidebarComponent = ({ bgColor, activeLinkColor, activeLinkBgColor, colorOnHover, linkColor }) => {
  const [activeLink, setActiveLink] = useState(null);

  const ClonedSidebarItem = ({ linkItem }) => cloneElement(
    <SidebarItem linkItem={linkItem} />,
    {
      activeLinkColor,
      activeLinkBgColor,
      colorOnHover,
      linkColor,
      activeLink,
      setActiveLink,
    }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
      dispatch(logout());
      navigate(LOGIN_PATH);
  };


  return (
    <ContainerSidebar className="d-flex flex-column justify-content-between"
        style={{
          position: "fixed",
          backgroundColor: bgColor || "black",
          borderRight: "1px solid rgba(0,0,0,0.1)",
          color: "white",
          height: "100%",
          width: "100px"
        }}
      >
        <div className="p-1 mt-2">
          <ResourceDataMap
            resourceData={links}
            resourceItem={ClonedSidebarItem}
            resourceName="linkItem"
          />
        </div>
        <div>
          <DivLogout onClick={handleLogout} className="text-center mt-3">
            <BiLogOut className="fs-5" />
          </DivLogout>
        </div>
      </ContainerSidebar>
  );
};

export default SidebarComponent;
