import React from "react";
import { Select } from "./NouveauVoyageurCss";
import { useFormContext } from "react-hook-form";

function SelectComponent({ options, name, }) {
  const { register } = useFormContext();
  return (
    <Select {...register(name)}>
      <optgroup>
        <option hidden></option>
      </optgroup>
      {options.map((option) => (
        <option key={option.value} value={option.value} className="pe-3">{option.text}</option>
      ))}
    </Select>
  );
}

export default SelectComponent;
