import React, { useState, useEffect, useRef } from "react";
import { MdLanguage } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import DrapeauAngleterre from "image/DrapeauAngleterre.jpg";
import DrapeauFrance from "image/DrapeauFrance.png";
import { Img } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { DropdownContainer, ContainerToggleDropdown, MenuItem, SmallDropdownMenu } from "./StyleNavbarAndElement";

const LanguageDropdown = ({ lang, setLang }) => {
  const [isLangDropdownVisible, setIsLangDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleLangDropdown = () => {
    setIsLangDropdownVisible(!isLangDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsLangDropdownVisible(false); // Ferme le dropdown si on clique à l'extérieur
    }
  };
// Ferme le dropdown si on clique à l'extérieur
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <ContainerToggleDropdown
        onClick={toggleLangDropdown}
        aria-haspopup="true"
        aria-expanded={isLangDropdownVisible}
        role="button"
      >
        <MdLanguage /> {lang} <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
      </ContainerToggleDropdown>
      {isLangDropdownVisible && (
        <SmallDropdownMenu>
          <MenuItem onClick={() => setLang("ANG")}>
            <Img src={DrapeauAngleterre} alt="English" /> Anglais
          </MenuItem>
          <MenuItem onClick={() => setLang("FR")}>
            <Img src={DrapeauFrance} alt="French" /> Français
          </MenuItem>
        </SmallDropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default LanguageDropdown;
