import React, { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  DropdownContainer,
  ContainerToggleDropdown,
  DropdownMenu,
  MenuItem,
} from "./StyleNavbarAndElement";
import { Link } from "react-router-dom";
import { HOTEL_RESERVATION_PATH } from "routes/navigation/navigationPaths";
import { useGetHotel } from "context/AppProvider";

const HotelDropdown = ({ nomHotel, setNomHotel }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); // Référence pour le conteneur du dropdown

  const { hotel } = useGetHotel();

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const hotelData = [
    { id: 1, hotel: "Dakar" },
    { id: 2, hotel: "Abidjan" },
    { id: 3, hotel: "Bamako" },
    { id: 4, hotel: "Nouakchott" },
    { id: 5, hotel: "Cotonou" },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Fermer le dropdown si on clique à l'extérieur
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <ContainerToggleDropdown
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isDropdownVisible}
        role="button"
      >
        {hotel?.nom_hotel} <IoMdArrowDropdown />
      </ContainerToggleDropdown>
      {isDropdownVisible && (
        <DropdownMenu>
          {hotelData.map((hot) => (
            <MenuItem key={hot.id} onClick={() => setNomHotel(hot.hotel)}>
              <Link
                className="dropdown-item fontSizeHotel"
                to={`${HOTEL_RESERVATION_PATH}/${hot.hotel}`}
              >
                Azalaï Hôtel {hot.hotel}
              </Link>
            </MenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default HotelDropdown;
