import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userslice from "./features/userslice";
import userSliceWithTokenValidation from "./features/userSliceWithTokenValidation";
import { splitShellSlice } from "./features/SplitShellSlice";
import { rxdApi } from "actions/rtkquery";
// import { apiSliceRTK } from "actions/apiSliceRTK";

const persistConfig = {
  key: "root-hotelier",
  storage,
  // whitelist: ["auth"],
};

// const rootReducers = combineReducers({
//     sidebarWidth: splitShellSlice.reducer,
//     auth: userSliceWithTokenValidation,
//     user: userslice,
//     [apiSliceRTK.reducerPath]: apiSliceRTK.reducer,
// })

// const persistedReducer = persistReducer(persistConfig, rootReducers)

const rootReducer = combineReducers({
  persist: persistReducer(
    persistConfig,
    combineReducers({
      sidebarWidth: splitShellSlice.reducer,
      auth: userSliceWithTokenValidation,
      user: userslice,
    })
  ),
  [rxdApi.reducerPath]: rxdApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(rxdApi.middleware),
});

export const persistor = persistStore(store);
