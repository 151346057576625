import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";

const getSchema = (isUpdate) => {
  return yup.object().shape({
    selectedOption: yup.string().required("Sélectionnez le titre est requis."),
    numero_passport: yup.string().required("Numéro de document est requis."),
    prenom: yup.string().required("Prénom est requis."),
    nom: yup.string().required("Nom est requis."),
    email: yup.string().email("Email invalide!").required("Email est requis."),
    telephone: yup.string().required("Numéro de téléphone est requis."),
    dateNaissance: yup.date(), // Plus de required ici
    adresse: yup.string(),
    langue: yup.string(),
    genre: yup.string(),
    dateDebut: yup.date(), // Plus de required ici
    dateFin: yup.date().min(yup.ref("dateDebut"), "La date de fin doit être postérieure à la date de début"),
    nombrePerson: isUpdate ? yup.string() : yup.string().required("Le nombre de personne est requis"),
    statut: isUpdate ? yup.string() : yup.string().required("Le statut de réservation est requis"),
    paiement: isUpdate ? yup.string() : yup.string().required("Le statut du paiement est requis"),
    nomReservation: isUpdate ? yup.string() : yup.string().required("Le nom de la réservation est requis"),
    source: isUpdate ? yup.string() : yup.string().required("La source de paiement est requise"),
    origine: isUpdate ? yup.string() : yup.string().required("L'origine est requis"),
    typedeClient: yup.string(),
    typeDeVoyage: yup.string(),
    demandeSpeciale: yup.string(),
  });
};

const VoyageurProvider = ({ children }) => {
  const isUpdate = useSelector((state) => state?.persist?.auth.isUpdate);
  const methods = useForm({
    resolver: yupResolver(getSchema(isUpdate)),
  });

  return (
    <FormProvider {...methods}>{children}</FormProvider>
  );
};

export default VoyageurProvider;
