import { ACCEUIL_PATH, GESTION_RESERVATION_PATH, GESTION_VOYAGEUR_PATH, MARKETING_PATH, RELATION_CLIENT_PATH, TEXTESPREPARES } from "./navigationPaths";
import { HiOutlineUsers, HiOutlineSpeakerphone } from "react-icons/hi";
import { CiUser } from "react-icons/ci";
import plus from "../../image/plus.png";
import client from "../../image/client.png";
import stat from "../../image/stat.png";
import dashbord from "../../image/dashboard.png";
import voyageur from "../../image/Voyageur.png";
import textprep from "../../image/text-prep.png";
import params from "../../image/params.png";
// import envoi from "../../image/envoi.png";

export const links = [
  {
    path: RELATION_CLIENT_PATH,
    icon: HiOutlineUsers,
    label: "Relation client",
    submenu: [
      { path: `/${ACCEUIL_PATH}`, label: "Tableau de bord", icon: dashbord },
      { path: "#", label: "Gestion clients", icon: client },
      { path: "#", label: "Statistiques", icon: stat },
      { path: "#", label: "Plus", icon: plus },
    ]
  },
  {
    path: `/${MARKETING_PATH}`,
    icon: HiOutlineSpeakerphone,
    label: "Marketing"
    // Pas de sous-menu pour Marketing pour le moment
  },
  {
    path: "/admin",
    icon: CiUser,
    label: "Admin",
    submenu: [
      { path: TEXTESPREPARES, label: "Textes préparés", icon: textprep },
      { path: GESTION_VOYAGEUR_PATH, label: "Base voyageurs", icon: voyageur },
      { path: GESTION_RESERVATION_PATH, label: "Gestion des réservations", icon: client },
      // { path: GESTION_ENVOIS_PATH, label: "Gestion des envois", icon: envoi },
      { path: "#", label: "Reporting", icon: stat },
      { path: "#", label: "Paramètres", icon: params }
    ]
  }
];
