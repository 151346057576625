import styled, { keyframes, css } from "styled-components";


// Style pour Navbar
export const Logo = styled.img`
  width: 25px;
  height: 25px;
`;

export const ParaLogo = styled.p`
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0 !important;
`;

export const swayAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const BellIcon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: #000000;
  font-family: Roboto;
  font-weight: 400;
  cursor: pointer;
  ${(props) =>
    props.isFloating &&
    css`
      animation: ${swayAnimation} 2s ease-in-out infinite;
      transform-origin: top center;
    `}
`;

/* style pour Dropdown */

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  left: -44px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  width: 200px;
  margin-top: 12px;
  @media(min-width: 320px) and (max-width: 767px){
   left: 0;
  }
`;
export const DropdownMenuAssistance = styled(DropdownMenu)`
  left: 0;
`;
export const IconStyle = {
    marginRight: "10px"
  };

export const MenuItem = styled.p`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: #d3eafd;
  }
`;


export const ContainerToggleDropdown = styled.div`
margin-left: 10px;
margin-right: 15px;
color: #000000;
font-family: Roboto;
font-weight: 400;
cursor: pointer;
transition: color 0.3s;
position: relative;
`;

export const SmallDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  margin-top: 12px;
  width: 150px;
`;
