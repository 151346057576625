import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { Controller, useFormContext } from "react-hook-form";
import "./InputCalendrier.css";

export default function SelectChambre({ optionsChambres }) {
  const OptionsChambres = optionsChambres.map((chambreRecup, index) => ({
    name: chambreRecup.numero,
    id: chambreRecup.id,
  }));
  const { control } = useFormContext();
  return (
    <div className="card flex justify-content-center">
      <Controller
        name="chambres"
        control={control}
        render={({ field }) => (
          <MultiSelect
            {...field}
            optionLabel="name"
            optionValue="id"
            options={OptionsChambres}
            filter
            display="chip"
            style={{ height: "40px", outline: "none" }}
          />
        )}
      />
    </div>
  );
}
