// import { useGetTextesPrepareByIdQuery, useSendMailMutation, useGetVoyageursQuery } from "actions/apiSliceRTK";
import CustomButton from "components/CustomComponent/Boutton/CustomButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MultiSelect } from "primereact/multiselect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetVoyageursQuery } from "actions/voyageur/query";
import { useGetTextesPrepareByIdQuery } from "actions/textPrepare/query";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  // background-color: #f7f7f7;
  border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // max-width: 500px;
  margin: 0 auto;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const DivContenu = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  box-sizing: border-box;
`;
const DetailTextPrep = () => {
  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const [selectedVoyageurs, setSelectedVoyageurs] = useState([]);
  const [sujet, setSujet] = useState("")
  const [contenu, setContenu] = useState("")
  const { id } = useParams();
  const idAsInt = parseInt(id, 10);
  const { data: email } = useGetTextesPrepareByIdQuery(idAsInt);
  const { data: voyageursData } = useGetVoyageursQuery( userId )
  const voyageurs = voyageursData?.data || [];
  const optionsVoyageurs = voyageurs.map((voyageur) => ({
    name: `${voyageur.prenom} ${voyageur.nom}`,
    id: voyageur.id
  }));
  const [sendMail] = useSendMailMutation();

const handleSendMail = async () => {
  const mailData = {
    status: "Envoyé",
    texte_preparer: id,
    voyageur: selectedVoyageurs,
    send_by: userId
  }
  console.log({ mailData });
  try {
    const result = await sendMail(mailData).unwrap();
    console.log("E-mail envoyé avec succes", result);
    toast.success("E-mail envoyé avec succes");
  } catch (error) {
    console.log("Erreur lors de l'unvoie du email", error);
    toast.error("Erreur lors de l'unvoie du email");
  }
};

  useEffect(() => {
    if (email) {
      setSujet(email.sujet_email)
      setContenu(email.contenu)
    }
  }, [idAsInt, email]);
  return (
    <FormWrapper className="mt-5">
      <FormRow>
        <Label>Selectionner un voyageur:</Label>
        <MultiSelect
          value={selectedVoyageurs}
          options={optionsVoyageurs}
          optionLabel="name"
          optionValue="id"
          onChange={(e) => setSelectedVoyageurs(e.value)}
          filter
          display="chip"
          style={{ height: "40px", outline: "none" }}
        />
      </FormRow>
      <FormRow>
        <Label>Sujet:</Label>
        <DivContenu dangerouslySetInnerHTML={{ __html: sujet }} />
      </FormRow>
      <FormRow>
        <Label>Contenu:</Label>
        <DivContenu dangerouslySetInnerHTML={{ __html: contenu }} />
      </FormRow>
      <CustomButton
        className="btn news"
        backgroundColor="#FFC857"
        padding="10px 30px"
        color="black"
        fontWeight="500"
        onClick={()=> handleSendMail()}
      >
        Envoyer
      </CustomButton>
      <ToastContainer className="mt-5" />
    </FormWrapper>
  );
};

export default DetailTextPrep;
