import { useGetHotelQuery } from "actions/hotel/queries";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import React, { createContext, useContext } from "react";

const AppContext = createContext({});

function AppProvider({ children }) {
  const {} = useGetCurrentUser();
  const { data, isLoading } = useGetHotelQuery();
  console.log({ data });

  return (
    <AppContext.Provider value={{ hotel: data ? data : null, isLoading }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;

export const useGetHotel = () => useContext(AppContext);
