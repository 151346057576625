import React, { useState } from "react";
// import { Contentpage, /* ContentSidebar Footerform*/ } from "../StyledAdmin";
import { useParams } from "react-router-dom";
import Header from "components/GestionEnvoie/Hearder";
// import { Adresse, Details } from "components/GestionEnvoie/FooterForm";
// import SidebarForm from "components/GestionEnvoie/SidebarForm";
import AboutYouForm from "./TabsFormPreStay/AboutYouForm";
import ServicePage from "./TabsFormPreStay/ServicePage";
import Sejour from "./Sejour";
import Validation from "./Validation";
import GestionEnvoiProvider from "./GestionEnvoiProvider";
import { useForm, FormProvider } from "react-hook-form";
// import { useGetServicesMutation } from "actions/apiSliceRTK";
import ValidForm from "./ValidForm";
import { toast } from "react-toastify";
import { useAddDataFormMutation } from "actions/service/mutation";

const FormPreStay = () => {
  const methods = useForm();
  const { setValue } = methods;
  const { voyageurId } = useParams();
  const [addDataForm] = useAddDataFormMutation();

  const headerData = [
    { nombre: "01", titre: "A propos de vous" },
    { nombre: "02", titre: "Votre séjour" },
    { nombre: "03", titre: "Les services" },
    { nombre: "04", titre: "Validation" },
  ];
  const [activeKey, setActiveKey] = useState(headerData[0].nombre);
  console.log(voyageurId);

  const onSubmit = async (formData) => {
    setValue("voyageur", voyageurId);
    console.log("Form data on submit:", formData); // Log the entire form data
    try {
      const result = await addDataForm(formData).unwrap();
      console.log("Form data submitted successfully:", result);
      toast.success("Form data submitted successfully");
      setActiveKey("05");
    } catch (err) {
      toast.error("Error submitting form data:", err);
      console.error("Error submitting form data:", err);
    }
  };


  const handlePrevious = () => {
    if (parseInt(activeKey) > 1) {
      setActiveKey((prev) => (parseInt(prev) - 1).toString().padStart(2, "0"));
    }
  };
  const handleNext = () => {
    if (parseInt(activeKey) < headerData.length) {
      setActiveKey((prev) => (parseInt(prev) + 1).toString().padStart(2, "0"));
    }
  };
  return (
    <div className="overflow-x-hidden">
      {/* <ContentSidebar className="col-2 p-0 object-fit-contain position-fixed">
        <SidebarForm />
      </ContentSidebar> */}
      <div
        className="d-flex flex-column justify-content-center p-3"
      >
        <div className="mt-4">
          <div className="d-flex justify-content-center">
            {headerData.map((data, index) => (
              <div
                key={index}
                onClick={() => setActiveKey(data.nombre)}
              >
                <Header
                  nombre={data.nombre}
                  titre={data.titre}
                  isActive={activeKey === data.nombre}
                />
              </div>
            ))}
          </div>

          <GestionEnvoiProvider>
            <FormProvider {...methods}>
              <form
                className="tab-content my-2"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                {activeKey === "01" && <AboutYouForm onClick={handleNext} />}
                {activeKey === "02" && <Sejour handleCancel={handlePrevious} handleClick={handleNext} />}
                {activeKey === "03" && <ServicePage handleCancel={handlePrevious} handleClick={handleNext} />}
                {activeKey === "04" && <Validation handleCancel={handlePrevious} handleClick={handleNext} />}
                {activeKey === "05" && <ValidForm handleCancel={handlePrevious} />}
              </form>
            </FormProvider>
          </GestionEnvoiProvider>
        </div>
      </div>
    </div>
  );
};

export default FormPreStay;
