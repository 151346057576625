// import { getUserStatus } from "redux/features/userSliceWithTokenValidation";

import { useSelector } from "react-redux";
import { getUserStatus } from "redux/features/userSliceWithTokenValidation";

/**
 *
 * @return {true|false}
 */
function useIsAuthenticate() {
    // const isAuthenticated = false;
    const isAuthenticated = useSelector(getUserStatus);
    return isAuthenticated;
}

export default useIsAuthenticate;
