import React from "react";
import { FaSearch } from "react-icons/fa";
import { Searchinput, SearchIcone, Input } from "./SearchInputStyle";

const SearchInput = ({ value, onChange, placeholder, classNameIcone, classNSearchInput }) => {
    return (
          <Searchinput className={classNSearchInput}>
                    {/* Icône de recherche à l'intérieur de la barre */}
                    <SearchIcone className={classNameIcone}>
                        <FaSearch />
                    </SearchIcone>
                    <Input
                        type="text"
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />
                </Searchinput>
    );
};

export default SearchInput;
