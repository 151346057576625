import React, { useState } from "react";
import { AdminTableEmail, AdminTableEmailTh } from "pages/Admin/StyledAdmin";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import HeaderEmailTextPrep from "./HeaderEmailTextPrep";
// import { useDeleteTextePrepareMutation, useGetTextesPrepareQuery } from "actions/apiSliceRTK";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DETAIL_TEXTESPREPARES_PATH, UPDATE_EMAIL_PATH } from "routes/navigation/navigationPaths";
import { useGetTextesPrepareByIdQuery } from "actions/textPrepare/query";
import { useDeleteTextePrepareMutation } from "actions/textPrepare/mutation";

function TableEmailTextPrepares({ switchedToEmail }) {
    const { categorie } = useOutletContext();
    // input recherche email
    const [valueSearch, setValueSearch] = useState("")
    // Id hotelier connecté
    const userId = useSelector((state) => state?.persist?.auth.user_id);
    const { data: emailsData, refetch } = useGetTextesPrepareByIdQuery( userId )
    const emails = emailsData?.data || [];
    const [deleteTextePrepare] = useDeleteTextePrepareMutation();
    const handleDelete = async (textPrepare) => {
        console.log(textPrepare.id);
        try {
          const data = await deleteTextePrepare(textPrepare.id).unwrap();
          refetch();
          toast.success("Email supprimée avec succès");
          console.log("Email supprimée avec succès", data);
        } catch (error) {
          toast.error("Erreur lors de la suppression:");
          console.error("Erreur lors de la suppression:", error);
        }
    };
    const navigate = useNavigate();
    const handleEdite = async (email) => {
        navigate(`${UPDATE_EMAIL_PATH}/${email.id}`);
    };
    const handleDetail = async (email) => {
        navigate(`${DETAIL_TEXTESPREPARES_PATH}/${email.id}`);
    };
  const filteredEmails = emails.filter((email) => {
    const matchesSearch = email.nom_email.toLowerCase().includes(valueSearch.toLowerCase());
    const matchesStatus = !categorie || email.statut === categorie;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="container margin-top-negative">
        <div className="row pb-2">
            <HeaderEmailTextPrep switchedButton={switchedToEmail} valueSearch={valueSearch} setValueSearch={setValueSearch} />
            <div className="table-responsive px-0">
                    <AdminTableEmail className="table text-center px-2" >
                        <thead>
                            <tr>
                                <AdminTableEmailTh scope="col" className="">
                                    Nom
                                </AdminTableEmailTh>
                                <AdminTableEmailTh scope="col" className="">
                                    Sujet
                                </AdminTableEmailTh>
                                <AdminTableEmailTh scope="col">
                                    Actions
                                </AdminTableEmailTh>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEmails.map((email, index) => (
                                <tr key={index} >
                                    <td className="py-4 ps-4 " style={{ color: "#667085", fontWeight: 400, fontFamily: "Roboto",
                                         fontSize: "16px", lineHeight: "18px" }}>{email.nom_email}</td>
                                    <td className="py-4" style={{ color: "#667085", fontWeight: 400, fontFamily: "Roboto", fontSize: "16px", lineHeight: "18px" }}>{email.sujet_email}</td>
                                    <td className="d-flex gap-3 py-4 align-items-center justify-content-center" >
                                        <FaEye className="fs-4" style={{ color: "#667085", cursor: "pointer", width: "18px" }} onClick={() => handleDetail(email)} />
                                        <BiEditAlt className="fs-4" style={{ color: "#026FE0", cursor: "pointer", width: "18px" }} onClick={() => handleEdite(email)} />
                                        <RiDeleteBin6Line className="fs-4" style={{ color: "#FF0A0A", cursor: "pointer", width: "18px" }} onClick={() => handleDelete(email)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </AdminTableEmail>
            <ToastContainer className="mt-5" />
            </div>
        </div>
    </div>
  )
}

export default TableEmailTextPrepares