import React from "react";
import sender from "image/sender.png";
// import concierge from "image/concierge.png";
import happy from "image/happy.png";
import satisfait from "image/satisfait.png";
// import ok from "image/ok.png";
import ph_star from "image/ph_star.png";
// import InfoCard from "components/InfoCard/InfoCard";
import { NavLink, useNavigate } from "react-router-dom";
import CardHeader from "./CardHeader";
// import { CardBody, CardColumn, OkIconContainer, PreTacheText } from "./Prestay";
import styled from "styled-components";
import { DEMANDE_CLIENT_PATH } from "routes/navigation/navigationPaths";
import DrapeauSenegal from "image/DrapeauSenegal.jpg"
import { formatDatee } from "utils/FormatDate";
import CardElemenTraite from "./CardElemenTraite";
import { OkIconContainer, PreTacheText } from "./Prestay";
import ok from "image/ok.png";


export const PsContain = styled.div`
  margin-top: 30px;
  font-family: Roboto;
`
const HistoriqueElementTraite = ({ voyageurs }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Retourne à la page précédente
  };
  return (
    <div className="row card d-flex flex-row justify-content-center border-0">
      <div className=" d-flex flex-row align-items-center px-0" style={{ backgroundColor: "#FFFFFF", height: "40px", width: "90%" }}>
          <NavLink className="atraiter" onClick={handleGoBack}>À traiter</NavLink>
          <span className="barre">/</span> <NavLink className="dtraiter" onClick={handleGoBack}>Déjà traités</NavLink>
      </div>
      <div className="card-body px-7">
        <PsContain>
          <CardHeader image={sender} text="Pre-Stay" />
          {voyageurs.length > 0 ? (
  voyageurs.map((voyageur, index) => (
    <NavLink to={`${DEMANDE_CLIENT_PATH}/${voyageur.id}`} key={index}>
      <CardElemenTraite
        padding="25px"
        size="15px"
        name={voyageur.nom}
        Pays="Senegal"
        countryImage={DrapeauSenegal}
        preStayDate={formatDatee(voyageur.created_at)}
      />
    </NavLink>
  ))
) : (
  <div className="d-flex align-center">
    <OkIconContainer>
      <img src={ok} alt="ok icon" />
    </OkIconContainer>
    <PreTacheText text="Aucun élément traité" />
  </div>
)}
        </PsContain>

        {/*  */}
        {/* <PsContain>
          <CardHeader image={concierge} text="Conciergerie" />
          <CardBody>
            <CardColumn >
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain> */}

        {/*  */}
        <PsContain>
          <CardHeader image={happy} text="In-Stay" />
          {voyageurs.length > 0 ?
          (voyageurs.map((voyageur, index)=>(
            <NavLink to={`${DEMANDE_CLIENT_PATH}/${voyageur.id}`} key={index}>
              <CardElemenTraite padding="25px" size="15px" name={voyageur.nom} Pays="Senegal"
              countryImage={DrapeauSenegal} emotion={satisfait} emotionText="Satisfait" inStayDate="Aujourd'hui" />
            </NavLink>
          ))) :
          ( <div className="d-flex align-center">
            <OkIconContainer>
              <img src={ok} alt="ok icon" />
            </OkIconContainer>
            <PreTacheText text="Aucun élément traité" />
          </div>)}
          {/* <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody> */}
        </PsContain>

        {/*  */}
        <PsContain>
          <CardHeader image={ph_star} text="Post-Stay" />
          {voyageurs.length > 0 ?
          (voyageurs.map((voyageur, index)=>(
            <NavLink to={`${DEMANDE_CLIENT_PATH}/${voyageur.id}`} key={index}>
              <CardElemenTraite padding="25px" size="15px" name={voyageur.nom} Pays="Senegal"
              countryImage={DrapeauSenegal} preStayDate={formatDatee(voyageur.created_at)} />
            </NavLink>
          ))) :
          <div className="d-flex align-center">
          <OkIconContainer>
            <img src={ok} alt="ok icon" />
          </OkIconContainer>
          <PreTacheText text="Aucun élément traité" />
        </div>}
          {/* <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody> */}
        </PsContain>
      </div>
    </div>
  );
};

export default HistoriqueElementTraite;