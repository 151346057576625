import React from "react";
import { FiCalendar } from "react-icons/fi";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import { RxDotFilled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { DETAIL_RESERVATION_PATH } from "routes/navigation/navigationPaths";

function HistoriqueCard({ montant, debut, fin, number, statut }) {
    const navigate = useNavigate();
    const handleDetails = async (reservationId) => {
      navigate(`/${DETAIL_RESERVATION_PATH}/${reservationId}`);
  };
    return (
        <div className="card-body  pe-3 row  rounded-1 d-flex align-items-center justify-content-center" onClick={() => handleDetails(number)} style={{
            marginTop: "5px",
            boxShadow: "1px 1px 3px gray",
            margin: "15px",
            padding: "0px",
            backgroundColor: "#fff",
            fontSize: "12px",
            cursor: "pointer"
        }}>
            <div className="col-4 fw-medium px-3 py-0 ">
                <p className=" m-0" style={{
                        fontSize: "20px", marginLeft: "47px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 500
                    }}>Réservation n°{number}</p>
                <p className="m-0" style={{ fontSize: "16px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 400 }}>Montant: <span style={{ fontWeight: 700 }}>{montant} FXOF</span></p>
            </div>
            <div className="col-3 fw-medium">
                <p className=" m-0" style={{ fontSize: "18px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 400 }}>Date d’envoi</p>
                <p className="m-0" style={{ fontSize: "18px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 400 }}><FiCalendar /> {debut}</p>
            </div>
            <div className="col-3 fw-medium">
                <p className="m-0" style={{ fontSize: "18px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 400 }}>Fin</p>
                <p className="m-0" style={{ fontSize: "18px", color: "#000000", fontFamily: "Poppins",
                        fontWeight: 400 }}><FiCalendar /> {fin}</p>
            </div>
            <div className="col-2 d-flex align-items-center">
                <span className="status rounded-pill pe-1 d-flex flex-row" style={styledRelationClientDetail.statusHistorique}><RxDotFilled className="fs-5" />{statut}</span>
            </div>
        </div>
    )
}

export default HistoriqueCard