import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const gestionEnvoiSchema = yup.object().shape({
  infos_supplementaires: yup.object().shape({
    date_naissance: yup.string().required("La date de naissance est requise"),
    heure_d_arrivee: yup.string().required("L'heure d'arrivée est requise"),
    type_client: yup.string().required("Le type de client est requis"),
    motif_voyage: yup.string().required("Le motif de voyage est requis"),
    voyageur: yup.string().required("Le voyageur est requis"),
  }),
  status: yup.string().required("Le statut est requis"),
  service_commande: yup.number().required("Le service commandé est requis"),
  voyageur: yup.number().required("Le voyageur est requis"),
});

const GestionEnvoiProvider = ({ children }) => {
  const methods = useForm({
    defaultValues: {
      infos_supplementaires: {
        date_naissance: "",
        heure_d_arrivee: "",
        type_client: "",
        motif_voyage: "",
        voyageur: "",
      },
      status: "",
      service_commande: "",
      voyageur: "",
    },
    resolver: yupResolver(gestionEnvoiSchema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default GestionEnvoiProvider;
