import React from "react";
import { ContInput, InputS } from "./SearchInputStyle";
import { LuSearch } from "react-icons/lu";

const InputSearch = ({
  value,
  onChange,
  placeholder,

  classNSearchInput,
  classInput,
}) => {
  return (
    <ContInput className={classNSearchInput}>
      <div style={{ padding: "0" }}>
        <LuSearch style={{ color: "#000", fontSize: 16 }} />
      </div>
      <InputS
        className={classInput}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </ContInput>
  );
};

export default InputSearch;
