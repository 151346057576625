import React, { useState, useEffect } from "react";
import { LuBellRing } from "react-icons/lu";
import LanguageDropdown from "./LanguageDropdown";
import AssistanceDropdown from "./AssistanceDropdown";
import HotelDropdown from "./HotelDropdown";
import hotelDefaultLogo from "image/azalai.png";
import SearchInput from "components/CustomComponent/SearchInput/SearchInput";
import { Logo, ParaLogo, BellIcon } from "./StyleNavbarAndElement";
import { useGetHotel } from "context/AppProvider";
import { GiHamburgerMenu } from "react-icons/gi";
import { ApiImage } from "routes/api/global-vars";

function NavbarComponent() {
  const [valueSearch, setValueSearch] = useState("");
  const [lang, setLang] = useState("FR");
  const [nomHotel, setNomHotel] = useState(" Dakar");
  const [isFloating, setIsFloating] = useState(false);

  const { hotel } = useGetHotel();

  // console.log({ ApiImage });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFloating(true);
      setTimeout(() => {
        setIsFloating(false);
      }, 2000);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        {/* Left part: Logo and Search */}
        <div className="d-flex align-items-center w-md-100">
          <a className="navbar-brand d-flex align-items-center gap-1" href="#">
          <Logo
            src={
              hotel?.logo_principal
                ? ApiImage + hotel?.logo_principal
                : hotelDefaultLogo
            }
            alt="Hotels"
            className=""
          />
          <ParaLogo className="d-md-flex d-none">
            {hotel?.nom_hotel || "cha"}
          </ParaLogo>
          </a>
          <SearchInput
                  classNSearchInput="d-md-flex bg-white d-none"
                  classNameIcone="text-dark"
                  placeholder="Rechercher"
                  value={valueSearch}
                  onChange={(e) => setValueSearch(e.target.value)}
                />
          <div className="d-flex ms-auto d-xl-none">
            <button
              className="navbar-toggler border-0 shadow-none outline-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ zIndex: 2 }}
            >
              <GiHamburgerMenu className="fs-1" />
            </button>
          </div>
        </div>
        <div className="collapse navbar-collapse justify-content-end" id="navbarScroll">
          <div className="">
            <ul className="navbar-nav ms-auto d-flex">
                <LanguageDropdown lang={lang} setLang={setLang} />
                <AssistanceDropdown />
                <BellIcon isFloating={isFloating}>
                  <LuBellRing />
                </BellIcon>
                <HotelDropdown nomHotel={nomHotel} setNomHotel={setNomHotel} />
                <SearchInput
                  classNSearchInput="d-md-none bg-white d-flex"
                  classNameIcone="text-dark"
                  placeholder="Rechercher"
                  value={valueSearch}
                  onChange={(e) => setValueSearch(e.target.value)}
                />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarComponent;