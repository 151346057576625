import React from "react";
// import { VscLayersDot } from "react-icons/vsc";
import { BsChatRightText } from "react-icons/bs";
import { HiOutlineEnvelope } from "react-icons/hi2";
// import { MdOutlineEventNote } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
// import { BiArrowToRight } from "react-icons/bi";
// import { BiArrowFromLeft } from "react-icons/bi";
import COLORS from "utils/Variables/color";
import sortie from "image/sortie.svg"
import instay from "image/instay.svg"
import postay from "image/posttay.svg"
import alleyes from "image/alleyes.svg"
// import { TEXTESPREPARES_AFTER_PATH, TEXTESPREPARES_BEFORE_PATH, TEXTESPREPARES_SEJOUR_PATH } from "routes/navigation/navigationPaths";

export const messages = [
    {
        icon: <BsChatRightText className="fs-5" />,
        titles: "Message", positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: `${COLORS.primaryBorderColor},`,
            cursor: "pointer"
        }} />, path: "messages", id: "message",
         nombre: "00"
    },
    {
        icon: <HiOutlineEnvelope className="fs-4" />, titles: "E-mail", positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: `${COLORS.primaryBorderColor}`,
            cursor: "pointer"
        }} />, path: "", id: "email",
         nombre: "01"
    },
    // { icon: <MdOutlineEventNote className="fs-4" />, title: "Formulaire", positionIcon: <RxDragHandleDots2 className="fs-4" style={{ color: `${COLORS.primaryBorderColor}`,
    // cursor: "pointer" }} />, path: "formulaire", id: "formulaire" },
]

export const affichAll = [
    {
        icon: (
            <img src={alleyes} alt="Sortie" className="fs-4" />
        ),
        titles: "Afficher tout", positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: `${COLORS.primaryBorderColor}`,
            cursor: "pointer"
        }} />, path: "all",
        nombre: "01"
    },
]
// const filteredReservations = reservations.filter((reservation) => {
//     if (!filterDate) return true;
//     const dateDebutSejour = new Date(reservation.date_debut_sejour);
//     return dateDebutSejour.toISOString().split("T")[0] === filterDate;
//   });

//   const reservationsPreStay = filteredReservations.filter(
//     (reservation) => new Date(reservation.date_debut_sejour) > dateNow
//   );
//   const nombrePreStay= reservationsPreStay.length;
export const enquete = [
    {
        icon: (
            <img src={sortie} alt="Sortie" className="fs-4" />
        ),
        value: "Prés_stay",
        titles: "Prés_stay",
        positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: `${COLORS.primaryBorderColor}`,
            cursor: "pointer"
        }} />,
         nombre: ""
    },
    {
        icon: (
            <img src={instay} alt="Sortie" className="fs-4" />
        ),
        value: "In_stay", titles: "In-Stay", positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: `${COLORS.primaryBorderColor}`,
            cursor: "pointer"
        }} />,
         nombre: ""
    },
    {
        icon: (<img src={postay} alt="Sortie" className="fs-4" />),
        value: "Post_stay", titles: "Post_stay", positionIcon: <RxDragHandleDots2 className="fs-4" style={{
            color: "#000000",
            cursor: "pointer"
        }} />,
         nombre: ""
    },
]

