import React from "react";
import headerImg from "image/headerImg.jpg";
import azalai from "image/azalai.png";
import DrapeauFr from "image/DrapeauFrance.png";
import DrapeauAn from "image/DrapeauAngleterre.jpg";
import instagram from "image/custom_re_rl (1).png";
import facebk from "image/custom_re_rl.png";
import imgBas from "image/imgbas.jpg";
import { BiArrowToRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  Bouton,
  Card2,
  ColImg,
  ContGE,
  Footer,
  Img,
  ImgLangue,
  Langue,
  Msg,
  Texte,
  TextOvaley,
  Titre,
} from "../StyledAdmin";
import ReservationCard from "components/GestionEnvoie/ReservationCard";
import FooterAccueil from "components/GestionEnvoie/FooterAccueil";
// import api from "routes/api/global-vars";
// import { formulaireByIdEndepoint } from "routes/api/endpoints";
import { useTranslation } from "react-i18next";

function GestionEnvoi() {
  const { t, i18n } = useTranslation();
  const resevationData = [
    { temps: t("Arrivée"), jour: "07", mois: "janv.", annee: "2015" },
    { temps: t("Départ"), jour: "14", mois: "janv.", annee: "2015" },
    {
      temps: t("Référence"),
      jour: "1",
      mois: "janv.",
      annee: "2015",
      classmoisan: "hidden",
    },
  ];
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ContGE className="container-fluid px-0">
      <div className="card bg-dark text-white col-lg-7 col-md-7 mx-auto border-0 rounded-0 h-100">
        <img src={headerImg} className="card-img" alt="..." />
        <TextOvaley className="card-img-overlay ">
          <Img src={azalai} alt="..." />
          <h5 className="card-title">
            Azalaï <br />
            Hôtel Dakar
          </h5>
        </TextOvaley>
      </div>
      <Langue>
        <button
          onClick={() => handleLanguageChange("fr")}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}>
          <ImgLangue src={DrapeauFr} alt="" />
          <span className="me-1 ms-1">FRANCAIS</span>
          <BiArrowToRight />
        </button>
        <button
          onClick={() => handleLanguageChange("en")}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}>
          <ImgLangue src={DrapeauAn} alt="" />
          <span className="me-1 ms-1">ANGLAIS</span>
          <BiArrowToRight />
        </button>
      </Langue>
      <Titre>
        <Texte className="text-uppercase">
          {t(
            "TRANSFORMONS VOTRE SÉJOUR CHEZ Azalaï Hôtel Dakar EN MOMENTS INOUBLIABLES"
          )}
        </Texte>
      </Titre>
      <Msg className="card rounded-0 border-0 bg-transparent mx-auto px-5">
        <div className="card-body">
          <p className="card-title">{t("Cher Monsieur Demo,")}</p>
          <p className="card-text">
            {t(
              "Merci d'avoir choisi  Azalaï Hôtel Dakar où nous aurons le plaisir de vous accueillir dans 7 jours."
            )}
          </p>
          <p className="card-text">
            {t(
              "Pour faire de votre séjour une expérience unique et personnalisée, nous avons préparé un questionnaire afin de préparer au mieux votre séjour à Azalaï :"
            )}
          </p>
          <Bouton>
            <Link
              to={"formulaire"}
              className="lienqus">
              {t("REMPLIR LE QUESTIONNAIRE")}
            </Link>
          </Bouton>
          <p className="text-center mb-2 mt-2">
            {t(
              "Utilisez le lien suivant si le bouton ci-dessus napparaît pas :"
            )}
          </p>
          <Link to className="text-center mb-2">
            https://guest.experience-hotel.com/fr/maison-mere/xlkwnnrdpdc0
          </Link>
          <p className="mb-1">
            {t("Nous avons hâte de vous accueillir à Azalaï.")}
          </p>
          <p className="text-green-100 mb-2">{t("Très chaleureusement,")}</p>
          <div>
            <h6 className="mb-1">Daher FARAH</h6>
            <p className="mb-1">{t("Votre Maître de Maison")}</p>
            <h6 className="mb-1">Azalaï Hôtel Dakar</h6>
            <p className="mb-1">+33 (0)1 42 80 00 00</p>
            <Link to className="lien text-primary mb-1">
              www.maisonmere.co
            </Link>
          </div>
          <div className="d-flex gap-2 mt-3">
            <img src={instagram} alt="" />
            <img src={facebk} alt="" />
          </div>
          <div className="row flex-column mt-4">
            <ColImg className="col-lg-12 px-0">
              <img src={headerImg} alt="" className="img-fluid w-100" />
            </ColImg>
            <Card2 className="col-lg-12 border pt-4 px-4">
              <h5>{t("Votre réservation")}</h5>
              <div className="row">
                {resevationData &&
                  resevationData.map((data, index) => (
                    <ReservationCard
                      key={index}
                      temps={data.temps}
                      jour={data.jour}
                      mois={data.mois}
                      annee={data.annee}
                      classmoisan={data.classmoisan}
                    />
                  ))}
              </div>
            </Card2>
            <div className="col-lg-12 px-0">
              <img src={imgBas} alt="" className="img-fluid w-100" />
            </div>
          </div>
        </div>
      </Msg>
      <Footer className="py-5 mt-4">
        <FooterAccueil />
      </Footer>
    </ContGE>
  );
}

export default GestionEnvoi;
