import styled from "styled-components";
import React from "react";
<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>
export const DivBreadCrumb = styled.div`
display: flex;
// justify-content: space-between;
// align-items: center;
// padding: 3px;
// background-color: #F0F0F0 !important;
// position: fixed;
// width: ${({ width }) => width};
// z-index: 1;
`;
export const Card = styled.div`
    border-radius: 8px;
    /* overflow-y: auto; */
    cursor: grab;
    height: 100%;
    user-select: none;
    /* -webkit-overflow-scrolling: touch; */
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* &::-webkit-scrollbar {
        width: 0;
    } */
`;
export const CardBody = styled.div`
    // height: fit-content; 
`;
export const ButtonClose = styled.div`
    color: #FFF;
`;
export const Boutton = styled.div`
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    background: #FFC857;
`;
export const Heading1 = styled.h1`
  font-size: 26px;
  font-family: Roboto;
  color: #FFFFFF;
  text-decoration: underline;
  text-align: center;
  padding-top: 50px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const Heading2 = styled.h2`
  font-size: 22px;
  font-family: Roboto;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
export const Heading3 = styled.h3`
  font-size: 20px;
  font-family: Roboto;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const Heading4 = styled.h4`
  font-size: 18px;
  font-family: Roboto;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const Heading5 = styled.h5`
  font-size: 16px;
  font-family: Roboto; 
  color: #008DF3;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Paragraph = styled.span`
  font-size: 14px;
  font-family: Roboto;
`;
export const Nom = styled.div`
  width: 140px;
`;
export const DivCentreInteret = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 30px;
  border: 1px solid #E0E0E0;
  border-radius: 4.55px;
  font-size: 14px;
  margin: 0 7px 7px 0;
  color: #000000;
  font-family: Roboto;
  font-weight: 400;
  line-height: 18px;
`;
export const DivSignePlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFC857;
  width: 15px;
  height: 15px;
  border: 1px solid #FFC857;
  border-radius: 50%;
`;
export const Img = styled.img`
    width: 30px;
    height: 20px;
    border-radius: 3px;
`;
export const ContCard = styled.div`
 height: 160px;
`
export const ContIcone = styled.div`
 /* color: #008DF3; */
 font-size: 20px;
`
export const Hithligne = styled.hr`
border: 0.1px solid #E0E0E0;
width: 100%;
height: 0.1px;
margin-top: 15px;
`
export const IconeBas = styled.img`
width: 35px;
margin-top: 0px;
`
export const Text = styled.p`
font-size: 16px;
font-weight: 400;
font-family: Roboto;
`
export const Cake = styled.img`
/* width: 15px; */
margin-top: -20px;
`