import { Date, Jour, MoisAnne } from "pages/Admin/StyledAdmin";
import { React } from "react";
function ReservationCard({ temps, jour, mois, annee, classmoisan }) {
    return (
            <div className="col-lg-3 pb-0">
                <p className="mt-2">{temps}</p>
                <Date>
                    <Jour>{jour} </Jour>
                    <MoisAnne className={classmoisan}>{mois} <br />{annee} </MoisAnne>
                </Date>
            </div>
    )
}

export default ReservationCard;