import React from "react";
// import { RxPerson } from "react-icons/rx";
import frames from "../../../image/Frames.png";
import user from "image/ph_user.png"

export default function PhotoProfile({ width, height, fontSize, marginTop }) {
    return (
        <div style={{
            position: "relative",
            background: "#E76F51",
            color: "white",
            borderRadius: "50%",
            height: height,
            width: width,
            textAlign: "center",
            fontSize: fontSize,
            marginTop: marginTop,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
        }}>
            <img src={user} alt="user" style={{ width: "50px" }} />
            {/* <RxPerson /> */}
            <div style={{
                display: "flex",
                gap: "5px",
                position: "absolute",
                top: "70px",
                right: "15px",
                width: "50px",
                height: "23px",
                borderRadius: "5px",
                border: "1px solid #FFC857",
                zIndex: 2,
                background: "#FFFFFF"
            }}>
             <img src={frames} alt="frame" style={{ width: "25px" }}/>
             <span className="text-secondary " style={{ fontSize: "12px" }}>4</span>
            </div>
        </div>
    );
}
