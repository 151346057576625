import React from "react"
import styled from "styled-components";
import icon from "./../../../image/Icon.png"
import linkedin from "../../../image/linkedin.png"
import iconX from "image/x.png";
import fb from "image/facebook2.png";

    const Separator = styled.div`
    border-left: 1px solid #CFD3D8;
    height: 30px;
    margin: 0px 20px;
     width: 20px
  `;
  const Card = styled.div`
  display: flex;
  align-items: center;
//   justify-content: center;
//   padding: 10px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  // width: 200px;
//   text-align: center;
`;
  //   const Number = styled.span`
  //   font-weight: bold;
  //   font-size: 1.5rem;
  // `;
  const TitleContainer = styled.div`
  display: flex;
  align-items: center; 
  margin-bottom: 5px;
`;

  const Icon = styled.img`
  margin-right: 8px; /* Adjust this value for spacing */
  width: 33px; 

`;

  const Title = styled.span`
  color: #667085;
  font-weight: 400;
  font-size: 14px;
`;

function ReservationReseau({ title }) {
  const reseSociaux = [
    { nom: "Awa Ndione Wade", statut: "Connecté 1254", desc: "Lorem ipsum dolor sit amet consectetur. Hendrerit enim duis urna sed...",
      lien: "Voir plus", icone: linkedin },
    { nom: "Awa Ndione Wade", statut: "Connecté 1254", desc: "Lorem ipsum dolor sit amet consectetur. Hendrerit enim duis urna sed...",
      lien: "Voir plus", icone: iconX },
    { nom: "Awa Ndione Wade", statut: "Connecté 1254", desc: "Lorem ipsum dolor sit amet consectetur. Hendrerit enim duis urna sed...",
      lien: "Voir plus", icone: fb }
  ]

  return (
    <div className="col border rounded d-flex flex-column px-3 py-3 bg-white" style={{ height: "450px" }}>
      <TitleContainer>
        <Icon src={icon} alt="icon" />
        <Title>{title}</Title>
      </TitleContainer>
      { reseSociaux.map((social, index) => (<>
        <Card className="" key={index}>
          <TitleContainer >
            <Icon src={social.icone} alt="icon" />
            <div style={{ display: "flex", flexDirection: "column", fontFamily: "Roboto", }}>
              <span style={{ color: "#02ACF6", fontSize: "14px", fontWeight: "400" }}>{social.nom}</span>
              <span style={{ color: "#566577", fontSize: "12px", fontWeight: "400", marginTop: "2px" }}>{social.statut}</span>
              <span style={{ color: "#000000", fontSize: "12px", fontWeight: "400", marginTop: "2px" }}>{social.desc}</span>
              <a href="#" style={{ color: "##2787FF", fontSize: "10px", fontWeight: "400", marginTop: "2px" }}>{social.lien}</a>
            </div>
          </TitleContainer>
        </Card>
        <Separator></Separator>
        </>
      ))}
    </div>
  )
}

export default ReservationReseau
