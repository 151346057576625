import styled from "styled-components";

export const ContainerDemandeClient = styled.div`
  /* border-radius: 10px; */
  background: #FFFFFF;
  border-radius: 14px;
`;
export const ContainSelectData = styled.div`
border-radius: 50px;
  border: 1px solid #ffc857;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
    gap: 1rem;
`
export const InputSelectDate = styled.select`
  padding: 5px;
  border: none;
  outline: none;
   appearance: none; /* Pour enlever la flèche sur la plupart des navigateurs */
  -webkit-appearance: none; /* Pour enlever la flèche sur Safari */
  -moz-appearance: none; /* Pour enlever la flèche sur Firefox */
  background-color: transparent; /* Garder le fond transparent */
`;
export const SpanIcon = styled.span`
font-size: 16px;
`
export const BoutonFiltre = styled.button`
background: white !important;
border: none !important;
font-weight: 700;
`
export const ContainerGestion = styled.div`
padding: 20px 40px;

@media(min-width: 320px) and (max-width: 424px){
padding: 0;
}
`