import styled from "styled-components";

export const Filtrer = styled.p `
color:#212529;
font-weight: 500;
font-size: 17px;
font-family: Roboto;
margin-top: 5px;
margin-left: 8px;
line-height: 20.12px;
letter-spacing: 0.523886501789093px;
`